import React, { useEffect, useState } from "react";
import { Button, Header } from "../components";
import {
  FiDelete,
  FiDownload,
  FiPackage,
  FiServer,
  FiSettings,
  FiUpload,
} from "react-icons/fi";
import {
  BsAndroid,
  BsBox2Heart,
  BsCircleFill,
  BsCpu,
  BsCpuFill,
  BsRecycle,
} from "react-icons/bs";
import {
  BiDollar,
  BiFingerprint,
  BiGitBranch,
  BiLink,
  BiPackage,
  BiPause,
  BiRecycle,
  BiTrash,
  BiUnlink,
} from "react-icons/bi";
import { GrAdd, GrAttachment, GrConfigure } from "react-icons/gr";
import {
  GiAustralia,
  GiPingPongBat,
  GiRecycle,
  GiTargetPrize,
  GiWantedReward,
} from "react-icons/gi";
import avatar from "../data/avatar.jpg";
import { TiTrash } from "react-icons/ti";
import {
  MdAnnouncement,
  MdMoney,
  MdOutlineTitle,
  MdSubject,
} from "react-icons/md";
import { RiMoneyCnyBoxLine } from "react-icons/ri";
import { GoVersions } from "react-icons/go";
import { useStatContext } from "../contexts/ContextProvider";
import {
  createAnnouncement,
  deleteAnnouncement,
  fetchAnnouncementsList,
  resetCore,
} from "../utils/HttpUtils";
import Modal from "../components/Modal";

const Announcements = () => {
  const { setCurrentToast } = useStatContext();
  const [announcements, setApplications] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [modalIcon, setModalIcon] = useState(<BiTrash />);
  const [questionableAction, setQuestionableAction] = useState(
    () => async () => {}
  );

  const [createAnnounceModalOpen, setCreateAnnounceModalOpen] = useState(false);
  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [announcementDescription, setAnnouncementDescription] = useState("");
  const [announcementAppId, setAnnouncementAppId] = useState("0");
  const [announcementLevel, setAnnouncementLevel] = useState("");
  const [announcementAction, setAnnouncementAction] = useState("");
  const [announcementOpen, setAnnouncementOpen] = useState(false);
  const [announcementIsImportant, setAnnouncementIsImportant] = useState(false);
  const [announcementHasButton, setAnnouncementHasButton] = useState(false);
  const [announcementIsForAll, setAnnouncementIsForAll] = useState(false);
  const [announcementFilteredDate, setAnnouncementFilteredDate] = useState("");
  const [announcementFilteredVersion, setAnnouncementFilteredVersion] =
    useState("");
  const [announcementIsPinned, setAnnouncementIsPinned] = useState(false);
  const [announcementExpire, setAnnouncementExpire] = useState("");
  const [announcementLastUpdate, setAnnouncementLastUpdate] = useState("");
  const [announcementButtonLabel, setAnnouncementButtonLabel] = useState("");
  const [announcementExclude, setAnnouncementExclude] = useState("");
  const [announcementInclude, setAnnouncementInclude] = useState("");
  const [announcementUrl, setAnnouncementUrl] = useState("");

  const resetNewAnnouncementValues = () => {
    setAnnouncementTitle("");
    setAnnouncementDescription("");
    setAnnouncementAppId("0");
    setAnnouncementLevel("");
    setAnnouncementAction("");
    setAnnouncementOpen(false);
    setAnnouncementIsImportant(false);
    setAnnouncementHasButton(false);
    setAnnouncementIsForAll(false);
    setAnnouncementFilteredDate("");
    setAnnouncementFilteredVersion("");
    setAnnouncementIsPinned(false);
    setAnnouncementExpire("");
    setAnnouncementLastUpdate("");
    setAnnouncementButtonLabel("");
    setAnnouncementExclude("");
    setAnnouncementInclude("");
    setAnnouncementUrl("");
  };

  const fetchAnnouncements = async () => {
    try {
      const resp = await fetchAnnouncementsList();
      setApplications(resp);
    } catch (error) {
      setCurrentToast({
        isToasted: true,
        text: error.message,
        color: "red-600",
      });
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <div
      className="relative m-10 p-5
      dark:bg-secondary-dark-bg font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Services" title="Announcements" />
      <div className="w-full">
        <div className="flex border-b-1 gap-5 pb-4 ml-2">
          <button
            onClick={() => {
              setCreateAnnounceModalOpen(true);
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 160 }}
          >
            Add Announce
            <span aria-hidden className="cybr-btn__glitch">
              Add Announce
            </span>
          </button>
          <button
            onClick={() => {
              setQuestionableAction(() => async () => {
                return resetCore();
              });
              setModalIcon(
                <FiSettings className="mx-auto text-[#1ffea9] text-3xl" />
              );
              setModalDesc("Are you sure you want to reload the core?");
              setModalOpen(true);
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 10 }}
          >
            Reload core
            <span aria-hidden className="cybr-btn__glitch">
              Reload core
            </span>
          </button>
        </div>

        <div className="flex flex-wrap items-start gap-5 mt-2">
          {announcements.length > 0 &&
            announcements.map((announcement) => {
              return (
                <div
                  key={announcement.id}
                  className="relative flex flex-col w-80 m-2 p-5 
            border-1 border-[#1ffea9] hover:bg-radial-gradient"
                >
                  <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
                  <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
                  <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
                  <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
                  <div className="flex items-center pb-1 border-b-1 border-gray-400">
                    <MdSubject className="text-[#1ffea9]" />
                    <p className="ms-2 text-md font-bold text-white">
                      {announcement.title}
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-white">
                    {announcement.description}
                  </p>
                  <div
                    className="flex justify-between items-center my-2
              border-1 border-[#1ffea9] bg-black text-white p-2 overflow-y-scroll"
                  >
                    <code className="flex items-center text-sm gap-1">
                      {`ID=${announcement.id};
                      appId=${announcement.appId};
                      level=${announcement.level};
                      action=${announcement.action};
                      openInApp=${announcement.openInApp};
                      isImportant=${announcement.isImportant};
                      hasButton=${announcement.hasButton};
                      forAll=${announcement.forAll};
                      pined=${announcement.isPinned};
                      filteredVersion=${announcement.filteredVersion};
                      filteredDate=${announcement.filteredDate};
                      expires=${announcement.expire};
                      lastUpdate=${announcement.last_update};
                      exclude=${announcement.exclude};
                      URL=${announcement.url};
                      Timestamp=${announcement.timestamp};`}
                    </code>
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <button
                      onClick={() => {
                        setQuestionableAction(() => () => {
                          return deleteAnnouncement(announcement.id);
                        });
                        setModalIcon(
                          <FiDelete className="mx-auto text-red-500 text-3xl" />
                        );
                        setModalDesc(
                          "Are you sure you want to delete this server?"
                        );
                        setModalOpen(true);
                      }}
                      type="button"
                      className="border-1 border-red-500
                   hover:bg-red-500
                   text-red-500
                   hover:text-black 
                   p-2 m-1"
                    >
                      <BiTrash />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="text-center w-56">
          {modalIcon}
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-[#1ffea9]">Confirm</h3>
            <p className="text-sm text-white">{modalDesc}</p>
          </div>
          <div className="flex gap-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  const result = await questionableAction();
                  fetchAnnouncements();
                  setCurrentToast({
                    isToasted: true,
                    text: result,
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }

                setModalOpen(false);
              }}
            >
              Yes
            </button>
            <button
              className="bg-red-500 py-1 text-black btn btn-light w-full"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={createAnnounceModalOpen}
        onClose={() => {
          resetNewAnnouncementValues();
          setCreateAnnounceModalOpen(false);
        }}
      >
        <div className="text-center">
          <MdAnnouncement className="mx-auto text-[#1ffea9] text-2xl" />
          <div className="my-4">
            <h3 className="text-lg font-black my-5 text-[#1ffea9]">
              Create Announcement
            </h3>
          </div>
          <div className="flex flex-col items-start">
            <label className="text-white">Title:</label>
            <input
              value={announcementTitle}
              onChange={(e) => {
                const announcementTitle = e.target.value;
                setAnnouncementTitle(announcementTitle);
              }}
              className="w-full px-1"
            />
          </div>
          <div className="flex flex-col items-start mt-2">
            <label className="text-white">Description:</label>
            <input
              value={announcementDescription}
              onChange={(e) => {
                const announcementDescription = e.target.value;
                setAnnouncementDescription(announcementDescription);
              }}
              className="w-full px-1"
            />
          </div>
          <div className="flex gap-5">
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">App ID:</label>
              <input
                value={announcementAppId}
                onChange={(e) => {
                  const appId = e.target.value;
                  setAnnouncementAppId(appId);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Level:</label>
              <input
                value={announcementLevel}
                onChange={(e) => {
                  const level = e.target.value;
                  setAnnouncementLevel(level);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Action:</label>
              <input
                value={announcementAction}
                onChange={(e) => {
                  const action = e.target.value;
                  setAnnouncementAction(action);
                }}
                className="w-full px-1"
              />
            </div>
          </div>
          <div className="flex justify-between gap-5">
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Open :</label>
              <select
                onChange={(e) => {
                  const open = e.target.value;
                  setAnnouncementOpen(open === "1" ? true : false);
                }}
                className="w-full px-1"
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Important :</label>
              <select
                onChange={(e) => {
                  const important = e.target.value;
                  setAnnouncementIsImportant(important === "1" ? true : false);
                }}
                className="w-full px-1"
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Button :</label>
              <select
                onChange={(e) => {
                  const hasButton = e.target.value;
                  setAnnouncementHasButton(hasButton === "1" ? true : false);
                }}
                className="w-full px-1"
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">All :</label>
              <select
                onChange={(e) => {
                  const isForAll = e.target.value;
                  setAnnouncementIsForAll(isForAll === "1" ? true : false);
                }}
                className="w-full px-1"
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
          </div>
          <div className="flex justify-between gap-5">
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Filtered Date:</label>
              <input
                value={announcementFilteredDate}
                onChange={(e) => {
                  const filteredDate = e.target.value;
                  setAnnouncementFilteredDate(filteredDate);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Filtered Version:</label>
              <input
                value={announcementFilteredVersion}
                onChange={(e) => {
                  const filteredVersion = e.target.value;
                  setAnnouncementFilteredVersion(filteredVersion);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Pinned :</label>
              <select
                onChange={(e) => {
                  const isPinned = e.target.value;
                  setAnnouncementIsPinned(isPinned === "1" ? true : false);
                }}
                className="w-full px-1"
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
          </div>
          <div className="flex gap-5">
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Expires:</label>
              <input
                value={announcementExpire}
                onChange={(e) => {
                  const expire = e.target.value;
                  setAnnouncementExpire(expire);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Last update:</label>
              <input
                value={announcementLastUpdate}
                onChange={(e) => {
                  const lastUpdate = e.target.value;
                  setAnnouncementLastUpdate(lastUpdate);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Button label:</label>
              <input
                value={announcementButtonLabel}
                onChange={(e) => {
                  const btnLabel = e.target.value;
                  setAnnouncementButtonLabel(btnLabel);
                }}
                className="w-full px-1"
              />
            </div>
          </div>
          <div className="flex flex-col items-start mt-2">
            <label className="text-white">Exclude:</label>
            <input
              value={announcementExclude}
              onChange={(e) => {
                const excludes = e.target.value;
                setAnnouncementExclude(excludes);
              }}
              className="w-full px-1"
            />
          </div>
          <div className="flex flex-col items-start mt-2">
            <label className="text-white">Include:</label>
            <input
              value={announcementInclude}
              onChange={(e) => {
                const includes = e.target.value;
                setAnnouncementInclude(includes);
              }}
              className="w-full px-1"
            />
          </div>
          <div className="flex flex-col items-start mt-2">
            <label className="text-white">URL:</label>
            <input
              value={announcementUrl}
              onChange={(e) => {
                const url = e.target.value;
                setAnnouncementUrl(url);
              }}
              className="w-full px-1"
            />
          </div>
          <div className="flex gap-4 mt-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                let appId = parseInt(announcementAppId);
                const data = {
                  title: announcementTitle,
                  level: announcementLevel,
                  action: announcementAction,
                  openInApp: announcementOpen,
                  isImportant: announcementIsImportant,
                  hasButton: announcementHasButton,
                  button: announcementButtonLabel,
                  forAll: announcementIsForAll,
                  exclude: announcementExclude,
                  include: announcementInclude,
                  url: announcementUrl,
                  appId: appId,
                  description: announcementDescription,
                  isPinned: announcementIsPinned,
                  filteredVersion: announcementFilteredVersion,
                  filteredDate: announcementFilteredDate,
                  expire: announcementExpire,
                };
                try {
                  if (
                    announcementTitle === "" ||
                    announcementDescription === ""
                  ) {
                    throw new Error("Necessary fields are empty");
                  }
                  const result = await createAnnouncement(data);
                  setCurrentToast({
                    isToasted: true,
                    text: result,
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }
                //reset all values
                resetNewAnnouncementValues();
                fetchAnnouncements();
                setCreateAnnounceModalOpen(false);
              }}
            >
              Create
            </button>
            <button
              className="bg-red-500 py-1 text-black w-full"
              onClick={() => {
                resetNewAnnouncementValues();
                setCreateAnnounceModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Announcements;
