import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Line,
  Overview,
  ServersStatus,
  ServersData,
  Groups,
  Applications,
  Announcements,
  TokenGenerate,
  TokenInfo,
  TokenExpires,
  TokenManagement,
} from "./pages";

import { useStatContext } from "./contexts/ContextProvider";
import "./App.css";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";

const App = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    currentMode,
    toast,
    token,
  } = useStatContext();

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      {!token ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            {/* dashboard  */}
            <Route path="/" element={<Overview />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/ecommerce" element={<Ecommerce />} />

            {/* dashboard  */}
            <Route path="/" element={<Overview />} />
            <Route path="/servers-status" element={<ServersStatus />} />
            <Route path="/servers-config" element={<ServersData />} />

            {/* services  */}
            <Route path="/groups" element={<Groups />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/announcements" element={<Announcements />} />

            {/* token  */}
            <Route path="/token-generate" element={<TokenGenerate />} />
            <Route path="/token-info" element={<TokenInfo />} />
            <Route path="/token-expires" element={<TokenExpires />} />

            {/* pages  */}
            <Route path="/orders" element={<Orders />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/customers" element={<Customers />} />

            {/* apps  */}
            <Route path="/kanban" element={<Kanban />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/color-picker" element={<ColorPicker />} />

            {/* charts  */}
            <Route path="/line" element={<Line />} />
            <Route path="/area" element={<Area />} />
            <Route path="/bar" element={<Bar />} />
            <Route path="/pie" element={<Pie />} />
            <Route path="/financial" element={<Financial />} />
            <Route path="/color-mapping" element={<ColorMapping />} />
            <Route path="/pyramid" element={<Pyramid />} />
            <Route path="/stacked" element={<Stacked />} />
          </Route>
          <Route path="*" element={404} />
        </Routes>
      ) : (
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                className="text-3xl 
          p-3 
          hover:drop-shadow-xl
          hover:bg-light-gray
          hidden
          text-white"
                style={{ background: currentColor, borderRadius: "50%" }}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div
              className="w-72 fixed sidebar
      dark:bg-secondary-dark-bg
      bg-black"
            >
              <Sidebar />
            </div>
          ) : (
            <div
              className="w-0
        dark:bg-secondary-dark-bg"
            >
              <Sidebar />
            </div>
          )}
          <div
            style={{ zIndex: "1000" }}
            className={`fixed flex h-10 
            p-2
          right-2 top-12
          bg-black
          border-${toast.color}
          border-1
          transition-all 
          ${
            toast.isToasted
              ? "translate-x-0 scale-100 opacity-100"
              : "scale-100 translate-x-10 opacity-0"
          }
          text-white justify-center items-center`}
          >
            <p className={`text-sm text-${toast.color}`}>{toast.text}</p>
          </div>

          <div
            style={{ zIndex: "0" }}
            className={`dark:bg-main-dark-bg bg-black
        min-h-screen w-full 
        ${activeMenu ? "md:ml-72" : "flex-2"}`}
          >
            <div
              className="static 
              bg-black
            dark:bg-main-dark-bg
        navbar w-full"
            >
              <Navbar />
            </div>
            <div>
              {themeSettings && <ThemeSettings />}

              <Routes>
                <Route path="/login" element={<Login />} />
                {/* dashboard  */}
                <Route path="/" element={<Overview />} />
                <Route path="/overview" element={<Overview />} />
                <Route path="/ecommerce" element={<Ecommerce />} />

                {/* dashboard  */}
                <Route path="/" element={<Overview />} />
                <Route path="/servers-status" element={<ServersStatus />} />
                <Route path="/servers-config" element={<ServersData />} />

                {/* services  */}
                <Route path="/groups" element={<Groups />} />
                <Route path="/applications" element={<Applications />} />
                <Route path="/announcements" element={<Announcements />} />

                {/* token  */}
                <Route path="/token-generate" element={<TokenGenerate />} />
                <Route path="/token-info" element={<TokenInfo />} />
                <Route path="/token-expires" element={<TokenExpires />} />
                <Route path="/token-management" element={<TokenManagement />} />

                {/* pages  */}
                <Route path="/orders" element={<Orders />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/customers" element={<Customers />} />

                {/* apps  */}
                <Route path="/kanban" element={<Kanban />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/color-picker" element={<ColorPicker />} />

                {/* charts  */}
                <Route path="/line" element={<Line />} />
                <Route path="/area" element={<Area />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/financial" element={<Financial />} />
                <Route path="/color-mapping" element={<ColorMapping />} />
                <Route path="/pyramid" element={<Pyramid />} />
                <Route path="/stacked" element={<Stacked />} />
                <Route path="*" element={404} />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
