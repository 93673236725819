import React, { useEffect, useRef, useState } from "react";
import { Button, Header } from "../components";
import {
  FiDelete,
  FiDownload,
  FiServer,
  FiSettings,
  FiUpload,
} from "react-icons/fi";
import {
  BsBox2Heart,
  BsCircleFill,
  BsCpu,
  BsCpuFill,
  BsRecycle,
} from "react-icons/bs";
import {
  BiDollar,
  BiGroup,
  BiLink,
  BiPause,
  BiPlay,
  BiRecycle,
  BiTrash,
  BiUnlink,
  BiWindows,
} from "react-icons/bi";
import { GrAdd, GrAttachment, GrGroup } from "react-icons/gr";
import {
  GiAustralia,
  GiPingPongBat,
  GiRecycle,
  GiTargetPrize,
  GiWantedReward,
} from "react-icons/gi";
import avatar from "../data/avatar.jpg";
import { TiTrash } from "react-icons/ti";
import { MdDelete, MdMoney } from "react-icons/md";
import { RiMoneyCnyBoxLine } from "react-icons/ri";
import {
  fetchGroupsList,
  fetchGroupFreeServersList,
  addServerToGroup,
  deleteServerFromGroup,
  changeGroupStatus,
  deleteGroup,
  createGroup,
  resetCore,
} from "../utils/HttpUtils";
import { useStatContext } from "../contexts/ContextProvider";
import Modal from "../components/Modal";

const Groups = () => {
  const { setCurrentToast } = useStatContext();
  const [currentApp, setCurrentApp] = useState(
    localStorage.getItem("current_app")
  );
  const [groups, setGroups] = useState([]);

  const [addModalOpen, setAddModalOpen] = useState(false);

  const [groupFreeServers, setGroupFreeServers] = useState([]);
  const [groupIdToAddServer, setGroupIdToAddServer] = useState(0);
  const [serverIdToAddToGroup, setServerIdToAddToGroup] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [modalIcon, setModalIcon] = useState(<BiTrash />);
  const [questionableAction, setQuestionableAction] = useState(
    () => async () => {}
  );

  const [createGroupModalOpen, setCreateGroupModalOpen] = useState(false);

  const apps = {};
  for (var i = 0; i < groups.length; i++) {
    const group = groups[i];
    if (!apps[group.appName]) {
      apps[group.appName] = [];
    }
    apps[group.appName].push(group);
  }

  const [groupToCreateName, setGroupToCreateName] = useState("");
  const [groupToCreateDescription, setGroupToCreateDescription] = useState("");
  const [groupToCreateImage, setGroupToCreateImage] = useState("");
  const [groupToCreateAppId, setGroupToCreateAppId] = useState(0);
  const [groupToCreateServiceType, setGroupToCreateServiceType] = useState(0);
  const [groupToCreateAccessId, setGroupToCreateAccessId] = useState(0);

  const resetGroupToCreateValues = () => {
    setGroupToCreateName("");
    setGroupToCreateDescription("");
    setGroupToCreateImage("");
    setGroupToCreateAppId(0);
    setGroupToCreateServiceType(0);
    setGroupToCreateAccessId(0);
  };

  const fetchGroups = async () => {
    try {
      const resp = await fetchGroupsList();
      setGroups(resp);
    } catch (error) {
      setCurrentToast({
        isToasted: true,
        text: error.message,
        color: "red-600",
      });
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <div
      className="relative m-10 p-5
      dark:bg-secondary-dark-bg font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Services" title="Groups" />
      <div className="w-full">
        <div className="flex border-b-1 gap-5 pb-4 ml-2">
          <button
            onClick={() => {
              setCreateGroupModalOpen(true);
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 160 }}
          >
            Add group
            <span aria-hidden className="cybr-btn__glitch">
              Add group
            </span>
          </button>
          <button
            onClick={() => {
              setQuestionableAction(() => async () => {
                return resetCore();
              });
              setModalIcon(
                <FiSettings className="mx-auto text-[#1ffea9] text-3xl" />
              );
              setModalDesc("Are you sure you want to reload the core?");
              setModalOpen(true);
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 10 }}
          >
            Reload core
            <span aria-hidden className="cybr-btn__glitch">
              Reload core
            </span>
          </button>
        </div>
        <div
          className="inline-flex flex-wrap
        m-2 mt-4"
        >
          {Object.keys(apps).map((app) => {
            return (
              <button
                key={app}
                type="button"
                onClick={() => {
                  localStorage.setItem("current_app", app);
                  setCurrentApp(app);
                }}
                className={`inline-block ${
                  app === currentApp
                    ? "bg-[#1ffea9] text-black"
                    : "bg-black text-[#1ffea9]"
                } px-6
              border-1 border-[#1ffea9]
              pb-2 pt-2.5 text-xs 
              font-medium uppercase 
              leading-normal 
              transition duration-150 ease-in-out 
              hover:bg-[#1ffea9]
              focus:outline-none
              focus:ring-0
               hover:text-black`}
              >
                {app}
              </button>
            );
          })}
        </div>

        <div className="flex items-start flex-wrap gap-2 mt-2">
          {apps[currentApp] &&
            apps[currentApp].map((group) => {
              let groupType = "";
              let groupCategory = "";
              if (parseInt(group.groupServiceType) == 1) {
                groupType = (
                  <span
                    className="text-sm p-1 
                    bg-gray-300 text-black
                      font-semibold"
                  >
                    Speed optimized
                  </span>
                );
              } else if (parseInt(group.groupServiceType) == 2) {
                groupType = groupType = (
                  <span
                    className="text-sm p-1 
                    bg-gray-300 text-black
                      font-semibold"
                  >
                    Traffic optimized
                  </span>
                );
              } else {
                groupType = (
                  <span
                    className="text-sm p-1 
                    bg-gray-300 text-black
                      font-semibold"
                  >
                    Normal
                  </span>
                );
              }
              if (parseInt(group.groupServiceAccess) == 1) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
                 text-black
                   font-semibold"
                  >
                    Premium
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 0) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    Free
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 2) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    CAT 1
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 3) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    CAT 2
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 4) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    V2RAY Free
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 5) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    V2RAY Premium
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 6) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    IKEv2 Free
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 7) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    IKEv2 Premium
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 8) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    STUNNEL Free
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 9) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    STUNNEL Premium
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 10) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    VLESS Free
                  </span>
                );
              } else if (parseInt(group.groupServiceAccess) == 11) {
                groupCategory = (
                  <span
                    className="text-sm p-1 bg-blue-500
             text-black
               font-semibold"
                  >
                    VLESS Premium
                  </span>
                );
              }
              return (
                <div
                  key={group.groupId}
                  className="relative flex flex-col w-[22rem] m-1 p-5 border-1 border-[#1ffea9] hover:bg-radial-gradient"
                >
                  <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
                  <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
                  <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
                  <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
                  <div className="flex items-center pb-1 border-b-1 border-white">
                    <img
                      className="w-8 h-8 border-[#1ffea9] border-1"
                      src={`https://apiv3.greennetapp.com/static/flags/${group.groupImage}.png`}
                    />
                    <p className="ms-2 text-sm font-bold text-white">
                      {group.groupName}
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-white">
                    {group.groupDescription}
                  </p>
                  <div className="flex justify-between items-center mt-2">
                    <p className="flex items-center text-sm gap-1">
                      <span className="font-semibold text-[#1ffea9]">App:</span>
                      <span className="text-white">{group.appName}</span>
                    </p>
                  </div>
                  <div
                    className="flex items-center flex-wrap
            gap-1 mt-2"
                  >
                    <span
                      className={`text-sm p-1 ${
                        group.groupStatus ? "bg-[#1ffea9]" : "bg-red-500"
                      } text-black
              font-semibold`}
                    >
                      {group.groupStatus ? "Active" : "Deactive"}
                    </span>
                    {groupType}
                    {groupCategory}
                  </div>
                  {group.servers && (
                    <div className="flex justify-center items-start mt-2 max-h-48 overflow-scroll">
                      <div className="">
                        <table
                          className="w-full 
                        text-sm text-left
                         text-white"
                        >
                          <thead className="text-[9.5px] text-[#1ffea9] border-1 border-[#1ffea9]  uppercase dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th className="py-3 ps-2">Server Name</th>
                              <th className="py-3 ps-2">IP</th>
                              <th className="py-3 p-2">A</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group.servers.map((server) => {
                              return (
                                <tr
                                  key={group.groupId + "" + server.serverId}
                                  className="text-[9.5px] border-1 border-[#1ffea9] dark:bg-gray-800 dark:border-gray-700"
                                >
                                  <th className="py-4 ps-2 font-medium text-[#1ffea9] whitespace-wrap dark:text-white">
                                    {server.serverName}
                                  </th>
                                  <td className="py-4 ps-2 text-[#1ffea9]">
                                    {server.serverIPv4}
                                  </td>
                                  <td className="py-4 p-2">
                                    <button
                                      onClick={() => {
                                        setQuestionableAction(() => () => {
                                          return deleteServerFromGroup(
                                            server.tieId
                                          );
                                        });
                                        setModalIcon(
                                          <FiDelete className="mx-auto text-red-500 text-3xl" />
                                        );
                                        setModalDesc(
                                          "Are you sure you want to delete this server?"
                                        );
                                        setModalOpen(true);
                                      }}
                                    >
                                      <span className="text-red-500 text-[11px]">
                                        X
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  <div className="flex items-center gap-2 mt-2">
                    <button
                      onClick={() => {
                        setQuestionableAction(() => () => {
                          return deleteGroup(group.groupId);
                        });
                        setModalIcon(
                          <MdDelete className="mx-auto text-red-500 text-3xl" />
                        );
                        setModalDesc(
                          "Are you sure you want to delete this group?"
                        );
                        setModalOpen(true);
                      }}
                      type="button"
                      className="border-1 border-red-500
                 hover:bg-red-500
                 text-red-500
                 hover:text-black 
                 p-2 m-1"
                    >
                      <BiTrash />
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          const resp = await fetchGroupFreeServersList(
                            group.groupId
                          );
                          setGroupFreeServers(resp);
                          setGroupIdToAddServer(group.groupId);
                          setAddModalOpen(true);
                        } catch (error) {
                          setCurrentToast({
                            isToasted: true,
                            text: error.message,
                            color: "red-500",
                          });
                        }
                      }}
                      className="border-1 border-[#1ffea9]
                 hover:bg-[#1ffea9]
                 text-[#1ffea9]
                 hover:text-black 
                 p-2 m-1"
                    >
                      <GrAdd />
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        setQuestionableAction(() => () => {
                          return changeGroupStatus(
                            group.groupId,
                            !group.groupStatus
                          );
                        });
                        setModalIcon(
                          <BiPlay className="mx-auto text-[#1ffea9] text-3xl" />
                        );
                        setModalDesc(
                          "Are you sure you want to change the status of this group?"
                        );
                        setModalOpen(true);
                      }}
                      className={`border-1 ${
                        group.groupStatus
                          ? "border-yellow-500 hover:bg-yellow-500 text-yellow-500"
                          : "border-[#1ffea9] hover:bg-[#1ffea9] text-[#1ffea9]"
                      } 
                 hover:text-black
                 p-2 m-1`}
                    >
                      {group.groupStatus ? <BiPause /> : <BiPlay />}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className="text-center w-56">
            {modalIcon}
            <div className="mx-auto my-4 w-48">
              <h3 className="text-lg font-black text-[#1ffea9]">Confirm</h3>
              <p className="text-sm text-white">{modalDesc}</p>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-[#1ffea9] py-1 text-black w-full"
                onClick={async () => {
                  try {
                    const result = await questionableAction();
                    fetchGroups();
                    setCurrentToast({
                      isToasted: true,
                      text: result,
                      color: "[#1ffea9]",
                    });
                  } catch (error) {
                    setCurrentToast({
                      isToasted: true,
                      text: error.message,
                      color: "red-500",
                    });
                  }

                  setModalOpen(false);
                }}
              >
                Yes
              </button>
              <button
                className="bg-red-500 py-1 text-black btn btn-light w-full"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
          }}
        >
          <div className="text-center">
            <GrAttachment className="mx-auto text-[#1ffea9] text-2xl" />
            <div className="my-4">
              <h3 className="text-lg font-black my-5 text-[#1ffea9]">
                Add Server to group
              </h3>
              <select
                className="border-1"
                onChange={(e) => {
                  setServerIdToAddToGroup(e.target.value);
                }}
              >
                {groupFreeServers &&
                  groupFreeServers.map((nServer) => {
                    return (
                      <option key={nServer.serverId} value={nServer.serverId}>
                        {nServer.serverName}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="flex gap-4 mt-4">
              <button
                className="bg-[#1ffea9] py-1 text-black w-full"
                onClick={async () => {
                  try {
                    const result = await addServerToGroup(
                      groupIdToAddServer,
                      serverIdToAddToGroup
                    );
                    setCurrentToast({
                      isToasted: true,
                      text: result,
                      color: "[#1ffea9]",
                    });
                  } catch (error) {
                    setCurrentToast({
                      isToasted: true,
                      text: error.message,
                      color: "red-500",
                    });
                  }

                  fetchGroups();
                  setAddModalOpen(false);
                }}
              >
                Attach
              </button>
              <button
                className="bg-red-500 py-1 text-black w-full"
                onClick={() => {
                  setAddModalOpen(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={createGroupModalOpen}
          onClose={() => {
            resetGroupToCreateValues();
            setCreateGroupModalOpen(false);
          }}
        >
          <div className="text-center">
            <BiWindows className="mx-auto text-[#1ffea9] text-2xl" />
            <div className="my-4">
              <h3 className="text-lg font-black my-5 text-[#1ffea9]">
                Create group
              </h3>
            </div>
            <div className="flex flex-col items-start">
              <label className="text-white">Group name:</label>
              <input
                value={groupToCreateName}
                onChange={(e) => {
                  const groupName = e.target.value;
                  setGroupToCreateName(groupName);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Group description:</label>
              <input
                value={groupToCreateDescription}
                onChange={(e) => {
                  const groupDescription = e.target.value;
                  setGroupToCreateDescription(groupDescription);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Image name:</label>
              <input
                value={groupToCreateImage}
                onChange={(e) => {
                  const groupImage = e.target.value;
                  setGroupToCreateImage(groupImage);
                }}
                className="w-full px-1"
              />
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Application:</label>
              <select
                onChange={(event) => {
                  const appId = parseInt(event.target.value);
                  setGroupToCreateAppId(appId);
                }}
              >
                {Object.keys(apps).map((app) => {
                  return (
                    <option key={app} value={apps[app][0].appId}>
                      {app}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col items-start mt-2">
                <label className="text-white">Service type:</label>
                <select
                  onChange={(event) => {
                    const serviceAccess = parseInt(event.target.value);
                    setGroupToCreateAccessId(serviceAccess);
                  }}
                >
                  <option value="0">Free</option>
                  <option value="1">Premium</option>
                  <option value="2">CAT 1</option>
                  <option value="3">CAT 2</option>
                  <option value="4">V2Ray Free</option>
                  <option value="5">V2Ray Premium</option>
                  <option value="6">IKEv2 Free</option>
                  <option value="7">IKEv2 Premium</option>
                  <option value="8">Stunnel Free</option>
                  <option value="9">Stunnel Premium</option>
                  <option value="10">VLESS Free</option>
                  <option value="11">VLESS Premium</option>
                </select>
              </div>
              <div className="flex flex-col items-start mt-2">
                <label className="text-white">Special profile:</label>
                <select
                  onChange={(event) => {
                    const serviceType = parseInt(event.target.value);
                    setGroupToCreateServiceType(serviceType);
                  }}
                >
                  <option value="0">Normal</option>
                  <option value="1">Speed optimized</option>
                  <option value="2">Traffic optimized</option>
                </select>
              </div>
            </div>
            <div className="flex gap-4 mt-4">
              <button
                className="bg-[#1ffea9] py-1 text-black w-full"
                onClick={async () => {
                  try {
                    if (
                      groupToCreateName === "" ||
                      groupToCreateDescription === "" ||
                      groupToCreateDescription === ""
                    ) {
                      throw new Error("Necessary fields are empty");
                    }

                    let appId = groupToCreateAppId;
                    if (appId === 0) {
                      appId = apps[Object.keys(apps)[0]][0].appId;
                      console.log(appId);
                      setGroupToCreateAppId(appId);
                    }

                    const result = await createGroup({
                      groupName: groupToCreateName,
                      groupDescription: groupToCreateDescription,
                      groupImage: groupToCreateImage,
                      groupAppId: appId,
                      groupServiceAccess: groupToCreateAccessId,
                      groupServiceType: groupToCreateServiceType,
                    });
                    setCurrentToast({
                      isToasted: true,
                      text: result,
                      color: "[#1ffea9]",
                    });
                  } catch (error) {
                    setCurrentToast({
                      isToasted: true,
                      text: error.message,
                      color: "red-500",
                    });
                  }

                  //reset all values
                  resetGroupToCreateValues();

                  fetchGroups();
                  setCreateGroupModalOpen(false);
                }}
              >
                Create
              </button>
              <button
                className="bg-red-500 py-1 text-black w-full"
                onClick={() => {
                  resetGroupToCreateValues();
                  setCreateGroupModalOpen(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Groups;
