import React from "react";

const Header = ({ category, title }) => {
  return (
    <div className="flex flex-col mb-10 items-start">
      <p className="text-white">{category}</p>
      <p
        className="text-3xl font-extrabold
       tracking-tight
       bg-white
       p-1"
      >
        {title}
      </p>
      <div className="h-[1px] w-full bg-white">dd</div>
    </div>
  );
};

export default Header;
