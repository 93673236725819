import React, { useEffect, useState } from "react";
import { Button, Header } from "../components";
import {
  FiDelete,
  FiDownload,
  FiPackage,
  FiServer,
  FiSettings,
  FiUpload,
} from "react-icons/fi";
import {
  BsAndroid,
  BsApple,
  BsBox2Heart,
  BsCircleFill,
  BsCpu,
  BsCpuFill,
  BsRecycle,
  BsWindows,
} from "react-icons/bs";
import {
  BiDollar,
  BiFingerprint,
  BiGitBranch,
  BiLink,
  BiPackage,
  BiPause,
  BiPlay,
  BiRecycle,
  BiTrash,
  BiUnlink,
  BiWindows,
} from "react-icons/bi";
import {
  GrAdd,
  GrAttachment,
  GrCloudSoftware,
  GrConfigure,
} from "react-icons/gr";
import {
  GiAustralia,
  GiPingPongBat,
  GiRecycle,
  GiTargetPrize,
  GiWantedReward,
} from "react-icons/gi";
import avatar from "../data/avatar.jpg";
import { TiTrash } from "react-icons/ti";
import { MdDelete, MdMoney } from "react-icons/md";
import { RiMoneyCnyBoxLine } from "react-icons/ri";
import { GoVersions } from "react-icons/go";
import {
  createApplication,
  deleteApplication,
  deleteServer,
  fetchAppConfig,
  fetchApplicationsList,
  resetCore,
  saveAppConfig,
  toggleApplicationStatus,
} from "../utils/HttpUtils";
import { useStatContext } from "../contexts/ContextProvider";
import Modal from "../components/Modal";
import { SiBmcsoftware } from "react-icons/si";

const Applications = () => {
  const { setCurrentToast } = useStatContext();
  const [applications, setApplications] = useState([]);
  const [requestedConfig, setRequestedConfig] = useState("");
  const [requestedConfigAppId, setRequestedConfigAppId] = useState(0);

  const [progressing, setProgressing] = useState(false);

  const [appConfigModalOpen, setAppConfigModalOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [modalIcon, setModalIcon] = useState(<BiTrash />);
  const [questionableAction, setQuestionableAction] = useState(
    () => async () => {}
  );

  const [createAppModalOpen, setCreateAppModalOpen] = useState(false);
  const [appName, setAppName] = useState("");
  const [appPackageName, setAppPackageName] = useState("");
  const [appPlatform, setAppPlatform] = useState("0");
  const [appVersion, setAppVersion] = useState("");
  const [appDescription, setAppDescription] = useState("");

  const resetCreateAppValues = () => {
    setAppName("");
    setAppPackageName("");
    setAppPlatform("0");
    setAppVersion("");
    setAppDescription("");
  };

  const fetchApplications = async () => {
    try {
      const resp = await fetchApplicationsList();
      setApplications(resp);
    } catch (error) {
      setCurrentToast({
        isToasted: true,
        text: error.message,
        color: "red-600",
      });
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  return (
    <div
      className="relative m-10 p-5
      dark:bg-secondary-dark-bg font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Services" title="Applications" />
      <div className="w-full">
        <div className="flex border-b-1 gap-5 pb-4 ml-2">
          <button
            onClick={() => {
              setCreateAppModalOpen(true);
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 160 }}
          >
            Add App
            <span aria-hidden className="cybr-btn__glitch">
              Add App
            </span>
          </button>
          <button
            onClick={() => {
              setQuestionableAction(() => async () => {
                return resetCore();
              });
              setModalIcon(
                <FiSettings className="mx-auto text-[#1ffea9] text-3xl" />
              );
              setModalDesc("Are you sure you want to reload the core?");
              setModalOpen(true);
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 10 }}
          >
            Reload core
            <span aria-hidden className="cybr-btn__glitch">
              Reload core
            </span>
          </button>
        </div>

        <div className="flex flex-wrap gap-5 mt-2">
          {applications.length > 0 &&
            applications.map((application) => {
              let platformIcon = <div></div>;
              if (application.appName.toLowerCase().includes("ios")) {
                platformIcon = <BsApple className="text-[#1ffea9]" />;
              } else if (application.appName.toLowerCase().includes("win")) {
                platformIcon = <BsWindows className="text-[#1ffea9]" />;
              } else {
                platformIcon = <BsAndroid className="text-[#1ffea9]" />;
              }
              return (
                <div
                  key={application.appId}
                  className="relative flex flex-col w-[32rem] m-2 p-5 border-1
             border-[#1ffea9] hover:bg-radial-gradient"
                >
                  <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
                  <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
                  <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
                  <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
                  <div className="flex items-center pb-1 border-b-1 border-gray-400">
                    {platformIcon}
                    {/* {application.appPlatform === "android" ? <BsAndroid className="text-[#1ffea9]" /> :
                    application.appPlatform === ""} */}
                    <p className="ms-2 text-md font-bold text-white">
                      {application.appName}
                    </p>
                    <p className="ms-5 text-sm font-bold text-[#1ffea9]">
                      ID:{" "}
                    </p>
                    <p className="text-sm ml-2 font-bold text-white">
                      {application.appId}{" "}
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-white">
                    {application.appDescription}
                  </p>
                  <div className="flex justify-between items-center mt-2">
                    <p className="flex items-center text-sm gap-1">
                      <BiFingerprint className="text-[#1ffea9]" />
                      <code className="text-white text-[12px]">
                        {application.appKey}
                      </code>
                    </p>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <p className="flex items-center text-sm gap-1">
                      <FiPackage className="text-[#1ffea9]" />
                      <code className="text-white">
                        {application.appPackageName}
                      </code>
                    </p>
                  </div>
                  <div
                    className="flex items-center flex-wrap
              gap-2 mt-2"
                  >
                    <span
                      className={`text-sm p-2 ${
                        application.appStatus ? "bg-[#1ffea9]" : "bg-red-500"
                      } text-black
                font-semibold`}
                    >
                      {application.appStatus ? "Active" : "Deactive"}
                    </span>
                    <p
                      className="flex items-center gap-1 text-sm p-2 bg-teal-500 text-black
                font-semibold"
                    >
                      <BiGitBranch />
                      <span>{application.appVersion}</span>
                    </p>
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <button
                      type="button"
                      onClick={() => {
                        setQuestionableAction(() => () => {
                          return deleteApplication(application.appId);
                        });
                        setModalIcon(
                          <MdDelete className="mx-auto text-red-500 text-3xl" />
                        );
                        setModalDesc(
                          "Are you sure you want to delete this application?"
                        );
                        setModalOpen(true);
                      }}
                      className="border-1 border-red-500
                   hover:bg-red-500
                   text-red-500
                   hover:text-black 
                   p-2 m-1"
                    >
                      <BiTrash />
                    </button>
                    <button
                      onClick={async () => {
                        try {
                          setProgressing(true);
                          setAppConfigModalOpen(true);
                          const resp = await fetchAppConfig(application.appId);
                          setRequestedConfig(resp);
                          setRequestedConfigAppId(application.appId);
                          setProgressing(false);
                        } catch (error) {
                          setCurrentToast({
                            isToasted: true,
                            text: error.message,
                            color: "red-500",
                          });
                          setRequestedConfig("");
                          setRequestedConfigAppId(0);
                          setProgressing(false);
                          setAppConfigModalOpen(false);
                        }
                      }}
                      type="button"
                      className="border-1 border-[#1ffea9]
                   hover:bg-[#1ffea9]
                   text-[#1ffea9]
                   hover:text-black 
                   p-2 m-1"
                    >
                      <GrConfigure />
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        setQuestionableAction(() => () => {
                          return toggleApplicationStatus(
                            application.appId,
                            !application.appStatus
                          );
                        });
                        setModalIcon(
                          <BiPlay className="mx-auto text-[#1ffea9] text-3xl" />
                        );
                        setModalDesc(
                          "Are you sure you want to change the status of this application?"
                        );
                        setModalOpen(true);
                      }}
                      className={`border-1 ${
                        application.appStatus
                          ? "border-yellow-500 hover:bg-yellow-500 text-yellow-500"
                          : "border-[#1ffea9] hover:bg-[#1ffea9] text-[#1ffea9]"
                      }
                   hover:text-black
                   p-2 m-1`}
                    >
                      {application.appStatus ? <BiPause /> : <BiPlay />}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="text-center w-56">
          {modalIcon}
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-[#1ffea9]">Confirm</h3>
            <p className="text-sm text-white">{modalDesc}</p>
          </div>
          <div className="flex gap-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  const result = await questionableAction();
                  fetchApplications();
                  setCurrentToast({
                    isToasted: true,
                    text: result,
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }

                setModalOpen(false);
              }}
            >
              Yes
            </button>
            <button
              className="bg-red-500 py-1 text-black btn btn-light w-full"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={createAppModalOpen}
        onClose={() => {
          resetCreateAppValues();
          setCreateAppModalOpen(false);
        }}
      >
        <div className="text-center">
          <SiBmcsoftware className="mx-auto text-[#1ffea9] text-2xl" />
          <div className="my-4">
            <h3 className="text-lg font-black my-5 text-[#1ffea9]">
              Create application
            </h3>
          </div>
          <div className="flex flex-col items-start">
            <label className="text-white">Application name:</label>
            <input
              value={appName}
              onChange={(e) => {
                const appName = e.target.value;
                setAppName(appName);
              }}
              className="w-full px-1"
            />
          </div>
          <div className="flex flex-col items-start mt-2">
            <label className="text-white">Package name:</label>
            <input
              value={appPackageName}
              onChange={(e) => {
                const packageName = e.target.value;
                setAppPackageName(packageName);
              }}
              className="w-full px-1"
            />
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Platform:</label>
              <select
                onChange={(event) => {
                  const appPlatform = event.target.value;
                  setAppPlatform(appPlatform);
                }}
              >
                <option value="0">Android</option>
                <option value="1">iOS</option>
                <option value="2">Linux</option>
                <option value="3">Mac</option>
                <option value="4">Windows</option>
              </select>
            </div>
            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Version:</label>
              <input
                value={appVersion}
                onChange={(e) => {
                  const appVersion = e.target.value;
                  setAppVersion(appVersion);
                }}
                className="w-full px-1"
              />
            </div>
          </div>
          <div className="flex flex-col items-start mt-2">
            <label className="text-white">Description:</label>
            <textarea
              value={appDescription}
              onChange={(e) => {
                const appDesc = e.target.value;
                setAppDescription(appDesc);
              }}
              className="w-full px-1"
            />
          </div>
          <div className="flex gap-4 mt-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  if (
                    appName === "" ||
                    appPackageName === "" ||
                    appPlatform === "" ||
                    appVersion === "" ||
                    appDescription == ""
                  ) {
                    throw new Error("Necessary fields are empty");
                  }

                  const result = await createApplication({
                    appName: appName,
                    appPackageName: appPackageName,
                    appPlatform: appPlatform,
                    appVersion: appVersion,
                    appDescription: appDescription,
                  });
                  setCurrentToast({
                    isToasted: true,
                    text: result,
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }
                //reset all values
                resetCreateAppValues();
                fetchApplications();
                setCreateAppModalOpen(false);
              }}
            >
              Create
            </button>
            <button
              className="bg-red-500 py-1 text-black w-full"
              onClick={() => {
                resetCreateAppValues();
                setCreateAppModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={appConfigModalOpen}
        size={progressing ? null : "w-1/2"}
        onClose={() => {
          setRequestedConfig("");
          setRequestedConfigAppId(0);
          setProgressing(false);
          setAppConfigModalOpen(false);
        }}
      >
        {progressing ? (
          <div className="p-24">
            <p className="text-[#1ffea9]">Loading...</p>
          </div>
        ) : (
          <div className="text-center">
            <GrConfigure className="mx-auto text-[#1ffea9] text-2xl" />
            <div className="my-4">
              <h3 className="text-lg font-black my-5 text-[#1ffea9]">
                Remote Config
              </h3>
            </div>
            <div>
              <textarea
                onChange={(e) => {
                  setRequestedConfig(e.target.value);
                }}
                className="p-1 w-full font-body"
                rows="20"
                value={requestedConfig}
              />
            </div>
            <div className="flex gap-4 mt-4">
              <button
                className="bg-[#1ffea9] py-1 text-black w-full"
                onClick={async () => {
                  try {
                    if (requestedConfig === "" || requestedConfigAppId === 0) {
                      throw new Error("Necessary fields are empty");
                    }
                    const result = await saveAppConfig(
                      requestedConfigAppId,
                      requestedConfig
                    );
                    setCurrentToast({
                      isToasted: true,
                      text: result,
                      color: "[#1ffea9]",
                    });
                  } catch (error) {
                    setCurrentToast({
                      isToasted: true,
                      text: error.message,
                      color: "red-500",
                    });
                  }
                  //reset all values
                  setRequestedConfig("");
                  setRequestedConfigAppId(0);
                  setProgressing(false);
                  fetchApplications();
                  setAppConfigModalOpen(false);
                }}
              >
                Save
              </button>
              <button
                className="bg-red-500 py-1 text-black w-full"
                onClick={() => {
                  setRequestedConfig("");
                  setRequestedConfigAppId(0);
                  setProgressing(false);
                  setAppConfigModalOpen(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Applications;
