import React from "react";
import { MdOutlineCancel } from "react-icons/md";

import { Button } from ".";
import { userProfileData } from "../data/dummy";
import avatar from "../data/skull.jpg";
import { useStatContext } from "../contexts/ContextProvider";

const UserProfile = () => {
  const { currentColor, handleNotClick, logout } = useStatContext();

  return (
    <div className="nav-item absolute right-1 border-1 border-[#1ffea9] bg-black top-16 dark:bg-[#42464D] w-96 font-network">
      <div className="relative flex flex-col p-5">
        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg text-[#1ffea9] dark:text-gray-200">
            Profile
          </p>
          <Button
            text={<MdOutlineCancel />}
            color="red-500"
            bgHoverColor="light-gray"
            onClick={() => handleNotClick("userProfile")}
            size="2xl"
            borderRadius="50%"
          />
        </div>
        <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
          <img
            className="h-24 w-24 rounded-md"
            src={avatar}
            alt="user-profile"
          />
          <div>
            <p className="font-semibold text-white text-xl dark:text-gray-200">
              {" "}
              Administrator{" "}
            </p>
            <p className="text-white text-sm font-semibold dark:text-gray-400">
              {" "}
              {localStorage.getItem("email")}{" "}
            </p>
          </div>
        </div>
        <div className="mt-5">
          <button
            onClick={() => {
              handleNotClick("userProfile");
              logout();
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 10 }}
          >
            Logout
            <span aria-hidden className="cybr-btn__glitch">
              Logout
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
