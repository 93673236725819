import { useRef } from "react";
export const UploadButton = ({ handleFile, content }) => {
  // REVISED
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files;
    handleFile(fileUploaded); // ADDED
  };

  return (
    <>
      <button
        className={`border-1 border-[#1ffea9]
                   hover:bg-[#1ffea9]
                   text-[#1ffea9]
                   hover:text-black 
                   p-2 m-1`}
        onClick={handleClick}
      >
        {content}
      </button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        multiple
        style={{ display: "none" }}
      />
    </>
  );
};

export default UploadButton;
