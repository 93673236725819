import React, { useState } from "react";
import { Button, Header } from "../../components";
import { useStatContext } from "../../contexts/ContextProvider";
import Modal from "../../components/Modal";
import { TiTicket } from "react-icons/ti";
import {
  createToken,
  createTokenUserzone,
  decreaseToken,
  deleteToken,
  increaseToken,
} from "../../utils/HttpUtils";
import { BiDevices, BiPause, BiPlay, BiStar, BiUser } from "react-icons/bi";
import { BsClock, BsFillStarFill, BsStarFill } from "react-icons/bs";
import { FiCreditCard, FiStar } from "react-icons/fi";
import { GoStarFill } from "react-icons/go";
import { RiMailStarFill, RiStarFill, RiUserStarFill } from "react-icons/ri";
import { HiHashtag } from "react-icons/hi";
import { SiLinux } from "react-icons/si";
import { MdPassword } from "react-icons/md";

const TokenGenerate = () => {
  const { setCurrentToast } = useStatContext();
  const [triggerTokenModalOpen, setTriggerTokenModalOpen] = useState(false);
  const [requestToken, setRequestToken] = useState("");
  const [tokenObject, setTokenObject] = useState({});
  const [tokenUserZoneCreds, setUserZoneCreds] = useState({});
  const [mode, setMode] = useState("0");
  const [days, setDays] = useState("1");
  const [devices, setDevices] = useState("0");
  const [managerId, setManagerId] = useState(0);

  const resetTriggerTokenValues = () => {
    setRequestToken("");
    setMode("0");
    setDays("1");
    setDevices("0");
    setManagerId(0);
  };

  let newTokenStr = "";
  if (
    tokenObject.token &&
    tokenObject.token.length > 1 &&
    tokenObject.token.length !== 19
  ) {
    for (var i = 0; i < tokenObject.token.length; i++) {
      if (i != 0 && i % 4 == 0) {
        newTokenStr += "-";
      }
      newTokenStr += tokenObject.token.charAt(i);
    }
  } else {
    newTokenStr = tokenObject.token;
  }

  return (
    <div
      className="relative m-10 p-5
    dark:bg-secondary-dark-bg font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Token" title="Token Generate" />
      <div className="w-full">
        <button
          onClick={() => {
            setTriggerTokenModalOpen(true);
          }}
          className="cybr-btn"
          style={{ "--primary-hue": 160 }}
        >
          Trigger
          <span aria-hidden className="cybr-btn__glitch">
            Trigger
          </span>
        </button>
      </div>
      <div className="flex justify-center mt-5">
        {Object.keys(tokenObject).length !== 0 && (
          <div className="relative flex flex-col w-1/2 py-8 border-1 border-[#1ffea9] p-5 mx-12 hover:bg-radial-gradient">
            <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
            <div className="absolute bg-[#1ffea9] right-0 top-0">
              <div className="flex items-center gap-1 p-1">
                <RiUserStarFill className="text-sm" />
                <span className="text-black text-sm font-semibold">
                  {tokenObject.id}
                </span>
              </div>
            </div>
            <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
            <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <TiTicket className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Token:</span>
              </div>

              <span className="text-black bg-white px-1 font-bold text-sm">
                {newTokenStr}
              </span>
            </div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <BiDevices className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Devices count:</span>
              </div>
              <div className="flex items-center">
                <p className="text-white text-sm">{tokenObject.device_num}</p>
                <p className="text-white ml-2 text-sm">device(s)</p>
              </div>
            </div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <BsClock className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Days:</span>
              </div>
              <div className="flex items-center">
                <p className="text-white text-sm">{tokenObject.days}</p>
                <p className="text-white ml-2 text-sm">day(s)</p>
              </div>
            </div>
            {tokenObject.user_id && tokenObject.user_id !== "" && (
              <div className="flex items-center gap-5 mt-4">
                <div className="flex items-center gap-2">
                  <BiUser className="text-[#1ffea9] text-sm" />
                  <span className="text-[#1ffea9] text-sm">User ID(s):</span>
                </div>
                <div className="flex items-center">
                  <p className="text-white text-[10px]">
                    {tokenObject.user_id}
                  </p>
                </div>
              </div>
            )}
            {tokenObject.start &&
              tokenObject.start !== "" &&
              tokenObject.start !== "0001-01-01T00:00:00Z" && (
                <div className="flex items-center gap-5 mt-4">
                  <div className="flex items-center gap-2">
                    <BiPlay className="text-[#1ffea9] text-sm" />
                    <span className="text-[#1ffea9] text-sm">Start time:</span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-white text-sm">
                      {tokenObject.start.slice(0, 19).replace("T", " ")}
                    </p>
                  </div>
                </div>
              )}
            {tokenObject.expire &&
              tokenObject.expire !== "" &&
              tokenObject.expire !== "0001-01-01T00:00:00Z" && (
                <div className="flex items-center gap-5 mt-4">
                  <div className="flex items-center gap-2">
                    <BiPause className="text-[#1ffea9] text-sm" />
                    <span className="text-[#1ffea9] text-sm">Expire time:</span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-white text-sm">
                      {tokenObject.expire.slice(0, 19).replace("T", " ")}
                    </p>
                  </div>
                </div>
              )}
            {tokenObject.os_id && tokenObject.os_id !== "" && (
              <div className="flex items-center gap-5 mt-4">
                <div className="flex items-center gap-2">
                  <SiLinux className="text-[#1ffea9] text-sm" />
                  <span className="text-[#1ffea9] text-sm">OS IDs:</span>
                </div>
                <div className="flex items-center">
                  <p className="text-white text-sm">{tokenObject.os_id}</p>
                </div>
              </div>
            )}
            {tokenUserZoneCreds && tokenUserZoneCreds.username !== "" && (
              <div className="flex items-center gap-5 mt-4">
                <div className="flex items-center gap-2">
                  <BiUser className="text-[#1ffea9] text-sm" />
                  <span className="text-[#1ffea9] text-sm">
                    Userzone Username:
                  </span>
                </div>
                <div className="flex items-center">
                  <p className="text-white text-sm">
                    {tokenUserZoneCreds.username}
                  </p>
                </div>
              </div>
            )}
            {tokenUserZoneCreds && tokenUserZoneCreds.password !== "" && (
              <div className="flex items-center gap-5 mt-4">
                <div className="flex items-center gap-2">
                  <MdPassword className="text-[#1ffea9] text-sm" />
                  <span className="text-[#1ffea9] text-sm">
                    Userzone Password:
                  </span>
                </div>
                <div className="flex items-center">
                  <p className="text-white text-sm">
                    {tokenUserZoneCreds.password}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        open={triggerTokenModalOpen}
        onClose={() => {
          resetTriggerTokenValues();
          setTriggerTokenModalOpen(false);
        }}
      >
        <div className="text-center">
          <TiTicket className="mx-auto text-[#1ffea9] text-2xl" />
          <div className="my-4">
            <h3 className="text-lg font-black my-5 text-[#1ffea9]">
              Create Token
            </h3>
          </div>
          <div
            className={`flex flex-col items-start ${
              mode === "0" ? "hidden" : "visible"
            }`}
          >
            <label className="text-white">Token:</label>
            <input
              value={requestToken}
              onChange={(e) => {
                const reqToken = e.target.value;
                setRequestToken(reqToken);
              }}
              className="w-full px-1"
            />
          </div>

          <div className="flex justify-between gap-5">
            {mode !== "3" && (
              <>
                <div className="flex flex-col items-start mt-2">
                  <label className="text-white">Days:</label>
                  <input
                    value={days}
                    onChange={(e) => {
                      const reqDays = e.target.value;
                      setDays(reqDays);
                    }}
                    className="w-full px-1"
                  />
                </div>
                <div className="flex flex-col items-start mt-2">
                  <label className="text-white">Devices:</label>
                  <input
                    value={devices}
                    onChange={(e) => {
                      const reqDevicesCount = e.target.value;
                      setDevices(reqDevicesCount);
                    }}
                    className="w-full px-1"
                  />
                </div>
                <div
                  className={`flex flex-col items-start mt-2 ${
                    mode === "0" ? "visible" : "hidden"
                  }`}
                >
                  <label className="text-white">Manager:</label>
                  <select
                    value={managerId}
                    onChange={(e) => {
                      const managerId = e.target.value;
                      setManagerId(parseInt(managerId));
                    }}
                    className="w-full px-1"
                  >
                    <option value="0">None</option>
                    <option value="2">Mahla.Hosseini</option>
                  </select>
                </div>
              </>
            )}

            <div className="flex flex-col items-start mt-2">
              <label className="text-white">Mode :</label>
              <select
                value={mode}
                onChange={(e) => {
                  const reqMode = e.target.value;
                  setMode(reqMode);
                }}
                className="w-full px-1"
              >
                <option value="0">Create</option>
                <option value="1">Increase</option>
                <option value="2">Decrease</option>
                <option value="3">Delete</option>
              </select>
            </div>
          </div>

          <div className="flex gap-4 mt-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  if (mode === "0") {
                    const daysCount = parseInt(days);
                    const devicesCount = parseInt(devices);
                    const result = await createTokenUserzone(
                      daysCount,
                      devicesCount,
                      managerId
                    );

                    setTokenObject(result.Obj);
                    setUserZoneCreds(result.UserObj);
                    setCurrentToast({
                      isToasted: true,
                      text: "Success",
                      color: "[#1ffea9]",
                    });
                  } else if (mode === "1") {
                    if (requestToken === "") {
                      throw new Error("Necessary fields are empty");
                    }
                    const daysVal = parseInt(days);
                    const devicesVal = parseInt(devices);

                    const result = await increaseToken(
                      requestToken,
                      daysVal,
                      devicesVal
                    );
                    setTokenObject(result);
                    setCurrentToast({
                      isToasted: true,
                      text: "Success",
                      color: "[#1ffea9]",
                    });
                  } else if (mode === "2") {
                    if (requestToken === "") {
                      throw new Error("Necessary fields are empty");
                    }
                    const daysVal = parseInt(days);

                    const result = await decreaseToken(requestToken, daysVal);
                    setTokenObject(result);
                    setCurrentToast({
                      isToasted: true,
                      text: "Success",
                      color: "[#1ffea9]",
                    });
                  } else {
                    const constReqToken = requestToken;
                    const result = await deleteToken(constReqToken);
                    setCurrentToast({
                      isToasted: true,
                      text: result,
                      color: "[#1ffea9]",
                    });
                  }
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }
                //reset all values
                resetTriggerTokenValues();
                setTriggerTokenModalOpen(false);
              }}
            >
              Ok
            </button>
            <button
              className="bg-red-500 py-1 text-black w-full"
              onClick={() => {
                resetTriggerTokenValues();
                setTriggerTokenModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TokenGenerate;
