import React, { useState } from "react";
import { Button, Header } from "../../components";
import { useStatContext } from "../../contexts/ContextProvider";
import Modal from "../../components/Modal";
import { TiTicket } from "react-icons/ti";
import { deleteTokenDevice, getToken } from "../../utils/HttpUtils";
import { RiStarFill } from "react-icons/ri";
import { BiDevices, BiPause, BiPlay, BiTrash, BiUser } from "react-icons/bi";
import { BsAndroid, BsApple, BsClock, BsWindows } from "react-icons/bs";
import { SiLinux } from "react-icons/si";
import { GiMachineGun } from "react-icons/gi";
import { GrVirtualMachine } from "react-icons/gr";
import { Delete } from "@syncfusion/ej2/spreadsheet";
import { FiDelete } from "react-icons/fi";
import { MdDelete } from "react-icons/md";

const TokenManagement = () => {
  const { setCurrentToast } = useStatContext();
  const [getTokenInfoModalOpen, setGetTokenInfoModalOpen] = useState(false);
  const [tokenObject, setTokenObject] = useState({});
  const [reqToken, setReqToken] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [modalIcon, setModalIcon] = useState(<BiTrash />);
  const [questionableAction, setQuestionableAction] = useState(
    () => async () => {}
  );

  const resetGetTokenInfoValues = () => {
    setReqToken("");
  };

  let newTokenStr = "";
  if (
    tokenObject.token &&
    tokenObject.token.length > 1 &&
    tokenObject.token.length !== 19
  ) {
    for (var i = 0; i < tokenObject.token.length; i++) {
      if (i != 0 && i % 4 == 0) {
        newTokenStr += "-";
      }
      newTokenStr += tokenObject.token.charAt(i);
    }
  } else {
    newTokenStr = tokenObject.token;
  }

  let tokenObjectDevices = [];
  if (
    Object.keys(tokenObject).length !== 0 &&
    tokenObject.user_id !== undefined &&
    tokenObject.user_id !== ""
  ) {
    let userIds = tokenObject.user_id.split(",");
    let osIds = tokenObject.os_id.split(",");
    for (let i = 0; i < userIds.length; i++) {
      let platform = "win";
      let userId = userIds[i];
      let osId = osIds[i];
      if (osId.length === 36) {
        platform = "ios";
      } else if (osId.length === 16) {
        platform = "android";
      }
      let device = {
        tokenId: tokenObject.id,
        userId,
        osId,
        platform,
      };
      tokenObjectDevices.push(device);
    }
  }

  return (
    <div
      className="relative m-10 p-5
    dark:bg-secondary-dark-bg font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Token" title="Token Management" />
      <div className="w-full">
        <button
          onClick={() => {
            setGetTokenInfoModalOpen(true);
          }}
          className="cybr-btn"
          style={{ "--primary-hue": 160 }}
        >
          Manage
          <span aria-hidden className="cybr-btn__glitch">
            Manage
          </span>
        </button>
      </div>
      <div className="flex justify-center mt-5">
        {Object.keys(tokenObject).length !== 0 && (
          <div className="relative flex flex-col py-8 border-1 border-[#1ffea9] p-5 mx-4 hover:bg-radial-gradient">
            <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
            <div className="absolute bg-[#1ffea9] right-0 top-0">
              <div className="flex items-center gap-1 p-1">
                <RiStarFill className="text-sm" />
                <span className="text-black text-sm font-semibold">
                  {tokenObject.id}
                </span>
              </div>
            </div>
            <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
            <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <TiTicket className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Token:</span>
              </div>

              <span className="text-black bg-white px-1 font-bold text-sm">
                {newTokenStr}
              </span>
            </div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <BiDevices className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Devices count:</span>
              </div>
              <div className="flex items-center">
                <p className="text-white text-sm">{tokenObject.device_num}</p>
                <p className="text-white ml-2 text-sm">device(s)</p>
              </div>
            </div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <BsClock className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Days:</span>
              </div>
              <div className="flex items-center">
                <p className="text-white text-sm">{tokenObject.days}</p>
                <p className="text-white ml-2 text-sm">day(s)</p>
              </div>
            </div>
            {tokenObject.start &&
              tokenObject.start !== "" &&
              tokenObject.start !== "0001-01-01T00:00:00Z" && (
                <div className="flex items-center gap-5 mt-4">
                  <div className="flex items-center gap-2">
                    <BiPlay className="text-[#1ffea9] text-sm" />
                    <span className="text-[#1ffea9] text-sm">Start time:</span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-white text-sm">
                      {tokenObject.start.slice(0, 19).replace("T", " ")}
                    </p>
                  </div>
                </div>
              )}
            {tokenObject.expire &&
              tokenObject.expire !== "" &&
              tokenObject.expire !== "0001-01-01T00:00:00Z" && (
                <div className="flex items-center gap-5 mt-4">
                  <div className="flex items-center gap-2">
                    <BiPause className="text-[#1ffea9] text-sm" />
                    <span className="text-[#1ffea9] text-sm">Expire time:</span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-white text-sm">
                      {tokenObject.expire.slice(0, 19).replace("T", " ")}
                    </p>
                  </div>
                </div>
              )}
            {tokenObjectDevices.length !== 0 && (
              <div className="flex flex-col mt-4 gap-4">
                <div className="flex items-center gap-5">
                  <GrVirtualMachine className="text-[#1ffea9]" />
                  <span className="text-[#1ffea9] text-sm">Devices:</span>
                </div>
                {tokenObjectDevices.map((device) => {
                  return (
                    <div
                      key={device.userId}
                      className="flex justify-between items-center
                      bg-[#1ffea9] text-black gap-5 border-1 border-transparent
                      hover:bg-transparent hover:text-[#1ffea9]
                      hover:border-1 hover:border-[#1ffea9]  font-body p-2"
                    >
                      <div className="flex items-center gap-5 ">
                        {device.platform === "android" ? (
                          <BsAndroid />
                        ) : device.platform === "ios" ? (
                          <BsApple />
                        ) : (
                          <BsWindows />
                        )}
                        <div className="flex flex-col gap-1">
                          <span className=" text-sm">{device.userId}</span>
                          <span className=" text-[11px]">{device.osId}</span>
                        </div>
                      </div>

                      <button
                        onClick={() => {
                          setQuestionableAction(() => () => {
                            return deleteTokenDevice(
                              tokenObject.token,
                              device.userId
                            );
                          });
                          setModalIcon(
                            <MdDelete className="mx-auto text-red-500 text-3xl" />
                          );
                          setModalDesc(
                            "Are you sure you want to delete this device?"
                          );
                          setModalOpen(true);
                        }}
                      >
                        <MdDelete className=" place-items-end " />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        open={getTokenInfoModalOpen}
        onClose={() => {
          resetGetTokenInfoValues();
          setGetTokenInfoModalOpen(false);
        }}
      >
        <div className="text-center">
          <MdDelete className="mx-auto text-[#1ffea9] text-2xl" />
          <div className="my-4">
            <h3 className="text-lg font-black my-5 text-[#1ffea9]">
              Delete device
            </h3>
          </div>
          <div className={`flex flex-col items-start`}>
            <label className="text-white">Token:</label>
            <input
              value={reqToken}
              onChange={(e) => {
                const requestedToken = e.target.value;
                setReqToken(requestedToken);
              }}
              className="w-full px-1"
            />
          </div>

          <div className="flex gap-4 mt-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  const result = await getToken(reqToken);
                  setTokenObject(result);
                  setCurrentToast({
                    isToasted: true,
                    text: "Success",
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }
                //reset all values
                resetGetTokenInfoValues();
                setGetTokenInfoModalOpen(false);
              }}
            >
              Ok
            </button>
            <button
              className="bg-red-500 py-1 text-black w-full"
              onClick={() => {
                resetGetTokenInfoValues();
                setGetTokenInfoModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="text-center w-56">
          {modalIcon}
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-[#1ffea9]">Confirm</h3>
            <p className="text-sm text-white">{modalDesc}</p>
          </div>
          <div className="flex gap-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  await questionableAction();
                  const result = await getToken(tokenObject.token);
                  setTokenObject(result);
                  setCurrentToast({
                    isToasted: true,
                    text: "Success",
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }

                setModalOpen(false);
              }}
            >
              Yes
            </button>
            <button
              className="bg-red-500 py-1 text-black btn btn-light w-full"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TokenManagement;
