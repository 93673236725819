import React, { useEffect, useState } from "react";
import {
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import { BiDownload, BiMemoryCard, BiUpload } from "react-icons/bi";
import { GrMemory, GrStorage } from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdMemory } from "react-icons/md";

const Overview = () => {
  const { currentColor, fetchOverview } = useStatContext();
  const [overal, setOveral] = useState({
    CPU: "0",
    MEMORY: "0",
    RX: "0.0",
    RXBW: "0.0",
    TX: "0",
    TXBW: "0.0",
  });
  useEffect(() => {
    const fetchOverals = async () => {
      try {
        const resp = await fetchOverview();
        setOveral((prev) => resp);
      } catch (error) {
        console.log(error);
      }
    };
    fetchOverals();

    setInterval(fetchOverals, 3000);
    return () => {
      clearInterval(fetchOverals);
    };
  }, []);

  return (
    <div
      className="relative mt-12 flex-col justify-center items-center
    m-10 p-2 p-5
    bg-black
    font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Dashboard" title="Overview" />
      <div
        className="flex flex-col gap-2 justify-start
      my-10 mx-8 p-1"
      >
        <h1 className="border-b-1 text-white">Overall Resources Usage</h1>
        <div className="flex justify-start gap-5">
          <div
            className="relative flex flex-col
          mt-6
          border-[#1ffea9] border-1
          h-36 w-96 justify-around
          hover:bg-radial-gradient"
          >
            <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
            <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
            <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
            <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
            <div
              className="flex justify-around items-center
           mx-2 p-1"
            >
              <FiCpu
                className="absolute top-2 left-2 text-8xl
                text-[#1ffea9]
               opacity-70"
              />
              <p
                className="absolute bottom-2 right-2 text-7xl
                text-white"
              >
                {overal.CPU}%
              </p>
            </div>
          </div>
          <div
            className="relative flex flex-col
          mt-6
          h-36 w-96 justify-around
          border-[#1ffea9] border-1
          hover:bg-radial-gradient"
          >
            <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
            <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
            <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
            <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
            <div
              className="flex justify-around items-center
           mx-2 p-1"
            >
              <GrMemory
                className="absolute top-2 left-2 text-8xl
                text-[#1ffea9]
                opacity-70"
              />
              <p
                className="absolute bottom-2 right-2 text-7xl
            text-white"
              >
                {overal.MEMORY}%
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h1 className="border-b-1 mx-8 text-white">Overall Traffic</h1>
        <div className="flex justify-start">
          <div
            className="relative flex flex-col justify-around
             h-52 w-64 m-10 p-2
        bg-black border-[#1ffea9] border-1
        hover:bg-radial-gradient"
          >
            <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
            <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
            <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
            <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
            <div
              className="flex justify-center  items-center
          mx-2 p-1"
            >
              <BiUpload className="text-xl text-[#1ffea9]" />
              <p className="ml-2 text-white">OUTBOUND</p>
            </div>
            <div className="flex mx-2 p-1 justify-start items-center">
              <GrStorage className="text-[#1ffea9]" />
              <p className="ms-2 text-white">{overal.TX} TB</p>
            </div>
            <div className="flex mx-2 p-1 justify-start items-center">
              <SiSpeedtest className="text-[#1ffea9]" />
              <p className="ms-2 text-white">{overal.TXBW} Gbps</p>
            </div>
          </div>
          <div
            className="relative flex flex-col justify-around h-52 w-64 
          m-10 p-2 border-[#1ffea9] border-1
          hover:bg-radial-gradient"
          >
            <div
              className="flex justify-center  items-center
          mx-2 p-1"
            >
              <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
              <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
              <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
              <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
              <BiDownload className="text-xl text-[#1ffea9]" />
              <p className="ml-2 text-white">INBOUND</p>
            </div>
            <div className="flex mx-2 p-1 justify-start items-center">
              <GrStorage className="text-[#1ffea9]" />
              <p className="ms-2 text-white">{overal.RX} TB</p>
            </div>
            <div className="flex mx-2 p-1 justify-start items-center">
              <SiSpeedtest className="text-[#1ffea9]" />
              <p className="ms-2 text-white">{overal.RXBW} Gbps</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
