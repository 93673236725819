import React, { useEffect, useState } from "react";
import { Header } from "../components";
import { FiDownload, FiServer, FiUpload } from "react-icons/fi";
import { BsCircleFill, BsCpu, BsCpuFill } from "react-icons/bs";
import { useStatContext } from "../contexts/ContextProvider";
import { GiNetworkBars } from "react-icons/gi";

const ServersStatus = () => {
  const [serversLoads, setServersLoads] = useState([]);
  const { fetchServersLoads } = useStatContext();

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const resp = await fetchServersLoads();
        setServersLoads(resp);
      } catch (error) {
        console.log(error);
      }
    };

    fetchServers();
    setInterval(fetchServers, 3000);
    return () => {
      clearInterval(fetchServers);
    };
  }, []);

  return (
    <div
      className="relative m-10 p-5
      dark:bg-secondary-dark-bg
      rounded-3xl
      font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Servers" title="Servers Status" />
      <div className="w-full">
        <div className="flex flex-wrap gap-5">
          {serversLoads.map((serverLoad, index) => {
            const status =
              parseInt(serverLoad.LASTUPDATE) + 120000 < Date.now()
                ? "Offline"
                : "Online";
            const bgColor = status === "Online" ? "bg-[#1ffea9]" : "bg-red-500";
            return (
              <div
                key={serverLoad.SERVER}
                className="relative flex flex-col
                 justify-between
                border-[#1ffea9] border-1
                  w-60 m-2 p-5
                  hover:bg-radial-gradient"
              >
                <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
                <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
                <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
                <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
                <div className="flex items-center pb-1 border-b-1 border-gray-400">
                  <FiServer className="text-[#1ffea9]" />
                  <p className="ms-2 text-sm text-white font-bold">
                    {serverLoad.SERVER}
                  </p>
                </div>
                <div className="flex items-center mt-2">
                  <p className="flex items-center text-sm gap-1">
                    <BsCpu className="text-[#1ffea9]" />
                    <span className="font-semibold text-white">CPU:</span>
                  </p>

                  <p className="ms-2 text-sm text-white">{serverLoad.CPU}%</p>
                </div>
                <div className="flex items-center mt-2">
                  <p className="flex items-center text-sm gap-1">
                    <GiNetworkBars className="text-[#1ffea9]" />
                    <span className="font-semibold text-white">IP:</span>
                  </p>

                  <p className="ms-2 text-sm text-white">{serverLoad.IP}</p>
                </div>
                <p className="mt-2 text-sm font-semibold text-white">
                  Bandwidth:
                </p>
                <div className="flex justify-between items-center mt-2">
                  <p className="flex items-center text-sm gap-1">
                    <FiUpload className="text-red-500" />
                    <span className="text-white">
                      {(parseFloat(serverLoad.TX) / 1000).toFixed(3)} TB
                    </span>
                  </p>

                  <p className="flex items-center text-sm gap-1">
                    <FiDownload className="text-[#1ffea9]" />
                    <span className="text-white">
                      {(parseFloat(serverLoad.RX) / 1000).toFixed(3)} TB
                    </span>
                  </p>
                </div>
                <p className="mt-2 text-sm font-semibold text-white">
                  Traffic:
                </p>
                <div className="flex justify-between items-center mt-2">
                  <p className="flex items-center text-sm gap-1">
                    <FiUpload className="text-red-500" />
                    <span className="text-white">{serverLoad.TXBW} Gbps</span>
                  </p>

                  <p className="flex items-center text-sm gap-1">
                    <FiDownload className="text-[#1ffea9]" />
                    <span className="text-white">{serverLoad.RXBW} Gbps</span>
                  </p>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <p className={`${bgColor} text-black p-1 text-sm`}>
                    {status}
                  </p>

                  <p className="bg-yellow-600 text-white p-1 text-sm">
                    {serverLoad.LC}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServersStatus;
