export const fetchServersList = async () => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");

    const response = await fetch(serviceUrl + "servers/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    const res = await response.json();
    if (res.servers) {
      return res.servers;
    }
    throw new Error(res.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchTelemetryServersList = async () => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");

    const response = await fetch(serviceUrl + "netstat/loads", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    const res = await response.json();

    if (res.servers) {
      return res.servers;
    }
    throw new Error(res.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const changeServerStatus = async (serverId, serverStatus) => {
  try {
    const data = { serverId: serverId, serverStatus: serverStatus };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "servers/changeserverstatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const changeServerAccess = async (serverId, serverAccess) => {
  try {
    const data = { serverId: serverId, serverAccess: serverAccess };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "servers/changeserveraccess", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const addRule = async (serverName, rule) => {
  try {
    const data = {
      serverName,
      serverRule: rule,
      serverRuleStatus: true,
    };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "rules/addRule", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const changeRule = async (serverName, rule, ruleId) => {
  try {
    const data = {
      ruleId,
      serverName,
      serverRule: rule,
      serverRuleStatus: true,
    };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "rules/changerule", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteRule = async (ruleId) => {
  try {
    const data = {
      ruleId,
    };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "rules/deleterule", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const toggleServerServiceStatus = async (serviceId, status) => {
  try {
    const data = { serviceId: serviceId, status: status };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "servers/changeservicestatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const uploadServerServiceProfile = async (formData) => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "servers/addservice", {
      method: "POST",
      headers: {
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: formData,
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteServerService = async (serviceId) => {
  try {
    const data = { serviceId: serviceId };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "servers/deleteservice", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const attachServer = async (serverName, serverIp, serverDesc) => {
  try {
    const data = {
      serverName: serverName,
      serverIPv4: serverIp,
      serverDescription: serverDesc,
    };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "servers/addserver", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteServer = async (serverId, serverName) => {
  try {
    const data = { serverId, serverName };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "servers/deleteserver", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchGroupsList = async () => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "groups/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.groups;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchGroupFreeServersList = async (groupId) => {
  try {
    const data = { groupId: groupId };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "groups/getfreeservers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.servers;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const addServerToGroup = async (groupId, serverId) => {
  try {
    const data = {
      tieServerId: parseInt(serverId),
      tieGroupId: groupId,
      tieStatus: true,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "groups/addserver", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteServerFromGroup = async (tieId) => {
  try {
    const data = {
      tieId: tieId,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "groups/deleteserver", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const changeGroupStatus = async (groupId, groupStatus) => {
  try {
    const data = {
      groupId: groupId,
      groupStatus: groupStatus,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "groups/changegroupstatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteGroup = async (groupId) => {
  try {
    const data = {
      groupId: groupId,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "groups/deletegroup", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const createGroup = async (data) => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "groups/addgroup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchApplicationsList = async () => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "apps/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.apps;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteApplication = async (appId) => {
  try {
    const data = {
      appId: appId,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "apps/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const createApplication = async (data) => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "apps/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchAppConfig = async (appId) => {
  try {
    const data = { appId: appId };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "apps/appconfig", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.config;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const saveAppConfig = async (appId, appConfig) => {
  try {
    const data = { appId: appId, appConfig: appConfig };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "apps/changeappconfig", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const toggleApplicationStatus = async (appId, status) => {
  try {
    const data = { appId: appId, appStatus: status };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "apps/changestatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchAnnouncementsList = async () => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "annc/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const createAnnouncement = async (data) => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "annc/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteAnnouncement = async (announceId) => {
  try {
    const data = {
      id: announceId,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "annc/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const createToken = async (days, devices) => {
  try {
    const data = {
      days: days,
      device_num: devices,
    };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "token/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const createTokenUserzone = async (days, devices, managerId) => {
  try {
    const tokenObj = {
      days: days,
      device_num: devices,
    };
    const data = {
      token: tokenObj,
      manager_id: managerId,
    };
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "token/generate-userzone", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteToken = async (tokenStr) => {
  try {
    const data = {
      token: tokenStr,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "token/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const increaseToken = async (tokenStr, value, deviceCount) => {
  try {
    const data = {
      token: tokenStr,
      value: value,
      device_num: deviceCount,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "token/increase", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const decreaseToken = async (tokenStr, value) => {
  try {
    const data = {
      token: tokenStr,
      value: value,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "token/decrease", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getToken = async (tokenStr) => {
  try {
    const data = {
      token: tokenStr,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "token/gettoken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchExpiringTokensList = async () => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "token/getexpires", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const resetCore = async () => {
  try {
    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "control/resetcore", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteTokenDevice = async (tokenStr, userId) => {
  try {
    const data = {
      token: tokenStr,
      userId: userId,
    };

    const serviceUrl = "https://adminv3.greennetapp.com/";
    const token = localStorage.getItem("site");
    const response = await fetch(serviceUrl + "token/delete-device", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.message;
    }
    const errBody = await response.json();
    throw new Error(errBody.message);
  } catch (err) {
    throw new Error(err.message);
  }
};
