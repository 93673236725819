import React from "react";

const Modal = ({ open, onClose, children, size }) => {
  return (
    <div
      onClick={onClose}
      className={`fixed inset-0
    flex justify-center items-center
    transition-colors
    ${open ? "visible bg-black/20" : "invisible"}`}
    >
      <div
        className={`relative bg-black border-[#1ffea9] ${
          size ? "w-1/2" : ""
        } border-1 shadow  transition-all
        ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
        <div className="p-5">
          {/* <button
            onClick={onClose}
            className="absolute top-3 right-3 p-1
         rounded-lg text-gray-400 hover:bg-gray-50
         hover:text-gray-600"
          >
            X
          </button> */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
