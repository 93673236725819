import React, { useEffect, useState } from "react";
import { useStatContext } from "../contexts/ContextProvider";

const Login = () => {
  const { loginAction } = useStatContext();
  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const [invalid, setInvalid] = useState({
    invalidEmail: false,
    invalidPassword: false,
    message: "",
  });

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    if (
      input.username !== "" &&
      input.password !== "" &&
      input.email.length > 5 &&
      input.password.length > 5
    ) {
      //dispatch action from hooks
      loginAction(input);
      return;
    }
    if (input.email === "" || input.email.length < 5) {
      console.log(input.email);
      const newInvalid = {
        invalidEmail: true,
        invalidPassword: false,
        message: "Invalid E-Mail",
      };
      setInvalid((prev) => newInvalid);
    } else if (input.password === "" || input.password.length < 5) {
      console.log(input.password);
      const newInvalid = {
        invalidEmail: false,
        invalidPassword: true,
        message: "Invalid Password",
      };
      setInvalid((prev) => newInvalid);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div
      className="flex h-screen
    bg-black
     justify-center items-center
      font-network"
    >
      <div className="w-full max-w-xs">
        <form
          onSubmit={handleSubmitEvent}
          className="border-[#1ffea9] border-1 p-5 mb-4"
        >
          <div className="mb-4">
            <label
              className="block text-white text-sm
             font-bold mb-2"
              htmlFor="user-email"
            >
              E-Mail
            </label>
            <input
              className={`appearance-none 
              border w-full 
              py-2 px-3
              ${invalid.invalidEmail ? "border-red-500" : ""} 
               text-white
              leading-tight 
              bg-black
              focus:outline-none focus:shadow-outline`}
              id="user-email"
              name="email"
              type="email"
              placeholder="example@email.com"
              aria-describedby="user-email"
              onChange={handleInput}
              aria-invalid="false"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className={`appearance-none border
             ${invalid.invalidPassword ? "border-red-500" : ""} w-full py-2 px-3
            text-white mb-3
              leading-tight focus:outline-none
              bg-black
              focus:shadow-outline`}
              id="password"
              name="password"
              type="password"
              onChange={handleInput}
              placeholder="******************"
            />
            {(invalid.invalidEmail || invalid.invalidPassword) && (
              <p className="text-red-500 text-xs italic">{invalid.message}</p>
            )}
          </div>
          <div className="flex items-center justify-center">
            <button
              className="border-1 border-[#1ffea9] text-[#1ffea9] hover:bg-[#1ffea9]
              hover:text-black
               font-bold py-2 px-4 
              focus:outline-none focus:shadow-outline
              btn-submit"
              type="submit"
            >
              Sign In
            </button>
          </div>
        </form>
        <p
          className="text-center
         text-[#1ffea9] font-semibold text-xs"
        >
          &copy;2024 Anonymith. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Login;
