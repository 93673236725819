import React, { useEffect, useRef, useState } from "react";
import { Button, Header } from "../components";
import {
  FiDelete,
  FiDownload,
  FiServer,
  FiSettings,
  FiUpload,
} from "react-icons/fi";
import { BsCircleFill, BsCpu, BsCpuFill } from "react-icons/bs";
import {
  BiDollar,
  BiLink,
  BiLira,
  BiPause,
  BiPlay,
  BiPlayCircle,
  BiStop,
  BiTrash,
  BiUnlink,
  BiWorld,
} from "react-icons/bi";
import { GrAdd, GrAttachment } from "react-icons/gr";
import { GiAttachedShield, GiPingPongBat } from "react-icons/gi";
import { useStatContext } from "../contexts/ContextProvider";
import Modal from "../components/Modal";
import {
  fetchServersList,
  changeServerStatus,
  changeServerAccess,
  toggleServerServiceStatus,
  uploadServerServiceProfile,
  deleteServerService,
  fetchTelemetryServersList,
  attachServer,
  deleteServer,
  resetCore,
  addRule,
  changeRule,
  deleteRule,
} from "../utils/HttpUtils";
import { SiStatuspage } from "react-icons/si";
import { RiServiceLine } from "react-icons/ri";
import UploadButton from "../components/UploadButton";
import { MdDeleteForever } from "react-icons/md";

const ServersData = () => {
  const [servers, setServers] = useState([]);
  const [telemetryServers, setTelemetryServers] = useState();
  let notAttachedServers = [];
  const addServerIpInputRef = useRef(null);
  const addServerDescInputRef = useRef(null);

  if (servers.length !== 0 && telemetryServers.length !== 0) {
    notAttachedServers = telemetryServers.filter(
      (tserver) =>
        !servers.find(
          (s) => s.serverName.toUpperCase() === tserver.SERVER.toUpperCase()
        )
    );
  }

  const { setCurrentToast } = useStatContext();
  const [addServerModal, setAddServerModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [modalIcon, setModalIcon] = useState(<BiTrash />);
  const [questionableAction, setQuestionableAction] = useState(
    () => async () => {}
  );

  const fetchServers = async () => {
    try {
      const resp = await fetchServersList();
      setServers(resp);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTelemetryServers = async () => {
    try {
      const resp = await fetchTelemetryServersList();
      setTelemetryServers(resp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // loading
    fetchServers();
    fetchTelemetryServers();
    // end loading
    return () => {};
  }, []);

  return (
    <div
      className="relative m-10 p-5
      dark:bg-secondary-dark-bg
      font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Servers" title="Servers Config" />
      <div className="w-full">
        <div className="flex border-b-1 gap-5 pb-4 ml-2">
          {/* <Button
            bgColor="[#1ffea9]"
            size="sm"
            text="Attach server"
            onClick={() => {
              setAddServerModal(true);
            }}
            borderRadius="10px"
          />
          <Button
            bgColor="red-500"
            size="md"
            text="Reload core"
            borderRadius="10px"
          /> */}
          <button
            onClick={() => {
              setAddServerModal(true);
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 160 }}
          >
            Attach server
            <span aria-hidden className="cybr-btn__glitch">
              Attach server
            </span>
          </button>
          <button
            onClick={() => {
              setQuestionableAction(() => async () => {
                return resetCore();
              });
              setModalIcon(
                <FiSettings className="mx-auto text-[#1ffea9] text-3xl" />
              );
              setModalDesc("Are you sure you want to reload the core?");
              setModalOpen(true);
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 10 }}
          >
            Reload core
            <span aria-hidden className="cybr-btn__glitch">
              Reload core
            </span>
          </button>
        </div>
        <div className="flex justify-start items-start flex-wrap gap-4 mt-2">
          {servers.map((server, index) => {
            const statusText = server.serverStatus
              ? "In Service"
              : "Out of service";
            const statusBgColor = server.serverStatus ? "[#1ffea9]" : "red-500";

            const isForAll = !server.hasOwnProperty("rule");
            let rule = "";
            if (!isForAll) {
              rule = server.rule;
            }

            return (
              <div
                key={server.serverName}
                className="relative flex flex-col
                 w-[22rem] m-1 p-5
                 border-[#1ffea9] border-1
                 hover:bg-radial-gradient"
              >
                <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
                <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
                <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
                <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
                <div className="flex items-center pb-1 border-b-1 border-gray-400">
                  <BiLink className="text-[#1ffea9]" />
                  <p className="ms-2 text-sm text-white font-bold">
                    {server.serverName}
                  </p>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p className="flex items-center text-sm gap-1">
                    <span className="font-semibold text-[#1ffea9]">IP:</span>
                    <span className="text-white">{server.serverIPv4}</span>
                  </p>

                  <p
                    className={`ms-2 bg-${statusBgColor}
                text-black text-sm px-2 py-1
                font-semibold`}
                  >
                    {statusText}
                  </p>
                </div>

                {server.services.length > 0 ? (
                  <div className="flex justify-center items-center mt-2">
                    <div className="relative overflow-x-auto">
                      <table className="w-full text-sm text-left rtl:text-right text-white  dark:text-gray-400">
                        <thead className="text-[9.5px] text-[#1ffea9] border-1 border-[#1ffea9]  uppercase dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th className="py-3 ps-2">Service</th>
                            <th className="py-3 ps-2">Protocol</th>
                            <th className="py-3 ps-2">IP</th>
                            <th className="py-3 ps-2">Port</th>
                            <th className="py-3 ps-2">S</th>
                            <th className="py-3 p-2">A</th>
                          </tr>
                        </thead>
                        <tbody>
                          {server.services.map((service, index) => {
                            return (
                              <tr
                                key={service.serviceId}
                                className="text-[9.5px] border-1 dark:bg-gray-800 border-[#1ffea9] dark:border-gray-700"
                              >
                                <th className="py-4 ps-2 font-medium text-[#1ffea9] whitespace-nowrap dark:text-white">
                                  {service.serviceType}
                                </th>
                                <td className="py-4 ps-2 text-[#1ffea9]">
                                  {service.serviceProtocol}
                                </td>
                                <td className="py-4 ps-2 text-[#1ffea9]">
                                  {service.serviceIpv4}
                                </td>
                                <td className="py-4 ps-2 text-[#1ffea9]">
                                  {service.servicePort}
                                </td>
                                <td className="py-4 ps-2 text-[#1ffea9]">
                                  <input
                                    type="checkbox"
                                    className="relative peer shrink-0
                                    appearance-none w-[10px] h-[10px] border-1 border-[#1ffea9] focus:outline-none
                                    checked:bg-[#1ffea9]"
                                    onChange={(event) => {
                                      setQuestionableAction(() => async () => {
                                        return toggleServerServiceStatus(
                                          service.serviceId,
                                          !service.status
                                        );
                                      });
                                      setModalIcon(
                                        <FiSettings className="mx-auto text-green-500 text-3xl" />
                                      );
                                      setModalDesc(
                                        "Are you sure you want change service status?"
                                      );
                                      setModalOpen(true);
                                    }}
                                    checked={service.status}
                                  />
                                </td>
                                <td className="py-4 p-2">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setQuestionableAction(() => async () => {
                                        return deleteServerService(
                                          service.serviceId
                                        );
                                      });
                                      setModalIcon(
                                        <span className="mx-auto text-red-500 text-3xl" />
                                      );
                                      setModalDesc(
                                        "Are you sure you want to delete this service?"
                                      );
                                      setModalOpen(true);
                                    }}
                                  >
                                    <span className="text-red-500 text-[13px] font-semibold">
                                      X
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="flex justify-center items-center gap-1 mt-2">
                  <button
                    type="button"
                    className="border-1 border-red-500
                   hover:bg-red-500
                   text-red-500
                   hover:text-black 
                   p-2 m-1"
                    onClick={() => {
                      setQuestionableAction(() => async () => {
                        return deleteServer(server.serverId, server.serverName);
                      });
                      setModalIcon(
                        <MdDeleteForever className="mx-auto text-red-500 text-3xl" />
                      );
                      setModalDesc(
                        "Are you sure you want to delete this server?"
                      );
                      setModalOpen(true);
                    }}
                  >
                    <BiUnlink />
                  </button>
                  <UploadButton
                    content={<GrAdd />}
                    handleFile={async (files) => {
                      if (files.length > 0) {
                        try {
                          const formData = new FormData();

                          for (var i = 0; i < files.length; i++) {
                            formData.append("files", files[i]);
                          }

                          formData.append("serverId", server.serverId);
                          const result = await uploadServerServiceProfile(
                            formData
                          );
                          setCurrentToast({
                            isToasted: true,
                            text: result,
                            color: "[#1ffea9]",
                          });
                          await fetchServers();
                          await fetchTelemetryServers();
                        } catch (error) {
                          setCurrentToast({
                            isToasted: true,
                            text: error.message,
                            color: "red-500",
                          });
                        }
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="border-1 border-orange-500
                   hover:bg-orange-500
                   text-orange-500
                   hover:text-black 
                   p-2 m-1"
                  >
                    <FiDownload />
                  </button>
                  <button
                    type="button"
                    className="border-1 border-teal-500
                   hover:bg-teal-500
                   text-teal-500
                   hover:text-black 
                   p-2 m-1"
                  >
                    <GiPingPongBat />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setQuestionableAction(() => async () => {
                        return changeServerStatus(
                          server.serverId,
                          !server.serverStatus
                        );
                      });
                      setModalIcon(
                        <BiPlayCircle className="mx-auto text-green-500 text-3xl" />
                      );
                      setModalDesc(
                        "Are you sure you want change server status?"
                      );
                      setModalOpen(true);
                    }}
                    className={`border-1 ${
                      server.serverStatus
                        ? "border-yellow-500 hover:bg-yellow-500 text-yellow-500"
                        : "border-[#1ffea9] hover:bg-[#1ffea9] text-[#1ffea9]"
                    }                
                   hover:text-black
                   p-2 m-1`}
                  >
                    {server.serverStatus ? <BiPause /> : <BiPlay />}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setQuestionableAction(() => async () => {
                        return changeServerAccess(
                          server.serverId,
                          !server.serverAccess
                        );
                      });
                      setModalIcon(
                        <BiDollar className="mx-auto text-green-500 text-3xl" />
                      );
                      setModalDesc(
                        "Are you sure you want to change server access level?"
                      );
                      setModalOpen(true);
                    }}
                    className={`border-1 ${
                      server.serverAccess
                        ? "border-[#1ffea9] hover:bg-[#1ffea9] text-[#1ffea9]"
                        : "border-red-500 hover:bg-red-500 text-red-500"
                    }
                   hover:text-black 
                   p-2 m-1`}
                  >
                    {server.serverAccess ? <BiDollar /> : <BiStop />}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setQuestionableAction(() => async () => {
                        const isForAllFinal = isForAll;
                        if (isForAllFinal) {
                          // set IR
                          return addRule(
                            server.serverName,
                            "DISTRIBUTION_BAN=IR_EXCEPT_FOREIGN"
                          );
                        } else {
                          // check if IR set to FOREIGN and if its FOREIGN delete rule
                          if (
                            rule.serverRule ===
                            "DISTRIBUTION_BAN=IR_EXCEPT_FOREIGN"
                          ) {
                            return changeRule(
                              server.serverName,
                              "DISTRIBUTION_BAN=FOREIGN_EXCEPT_IR",
                              rule.ruleId
                            );
                          } else {
                            return deleteRule(rule.ruleId);
                          }
                        }
                      });
                      setModalIcon(
                        <BiWorld className="mx-auto text-blue-500 text-3xl" />
                      );
                      setModalDesc(
                        "Are you sure you want to change server country access level?"
                      );
                      setModalOpen(true);
                    }}
                    className={`border-1 ${
                      isForAll
                        ? "border-blue-500 hover:bg-blue-500 text-blue-500"
                        : rule.serverRule ==
                          "DISTRIBUTION_BAN=IR_EXCEPT_FOREIGN"
                        ? "border-green-500 hover:bg-red-500 hover:border-red-500 text-red-500"
                        : "border-yellow-500 hover:bg-yellow-500 text-yellow-500"
                    }
                   hover:text-black 
                   p-2 m-1`}
                  >
                    {isForAll ? (
                      <BiWorld />
                    ) : rule.serverRule ==
                      "DISTRIBUTION_BAN=IR_EXCEPT_FOREIGN" ? (
                      <p className="text-[10px]">IR</p>
                    ) : (
                      <p className="text-[10px]">NIR</p>
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="text-center w-56">
          {modalIcon}
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-[#1ffea9]">Confirm</h3>
            <p className="text-sm text-white">{modalDesc}</p>
          </div>
          <div className="flex gap-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  const result = await questionableAction();
                  fetchServers();
                  fetchTelemetryServers();
                  setCurrentToast({
                    isToasted: true,
                    text: result,
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }

                setModalOpen(false);
              }}
            >
              Yes
            </button>
            <button
              className="bg-red-500 py-1 text-black btn btn-light w-full"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={addServerModal}
        onClose={() => {
          addServerIpInputRef.current.value = "";
          addServerDescInputRef.current.value = "";
          setAddServerModal(false);
        }}
      >
        <div className="text-center">
          <GrAttachment className="mx-auto" />
          <div className="my-4">
            <h3 className="text-lg font-black my-5 text-[#1ffea9]">
              Attach Server
            </h3>
            <select
              className="border-1"
              onChange={(e) => {
                addServerIpInputRef.current.value = e.target.value;
              }}
            >
              {notAttachedServers.map((nServer) => (
                <option key={nServer.SERVER} value={nServer.IP}>
                  {nServer.SERVER}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-between my-2 ">
            <label className="text-white">IP:</label>
            <input ref={addServerIpInputRef} className="border-1" />
          </div>
          <div className="flex justify-between my-2 ">
            <label className="text-white">Description:</label>
            <input ref={addServerDescInputRef} className="border-1 ml-2" />
          </div>
          <div className="flex gap-4 mt-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  const serverIp = addServerIpInputRef.current.value;
                  const serverName = notAttachedServers.find(
                    (ns) => ns.IP === serverIp
                  ).SERVER;
                  const serverDescription = addServerDescInputRef.current.value;

                  const result = await attachServer(
                    serverName,
                    serverIp,
                    serverDescription
                  );
                  setCurrentToast({
                    isToasted: true,
                    text: result,
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }

                addServerIpInputRef.current.value = "";
                addServerDescInputRef.current.value = "";

                fetchServers();
                fetchTelemetryServers();
                setAddServerModal(false);
              }}
            >
              Attach
            </button>
            <button
              className="bg-red-500 py-1 text-black w-full"
              onClick={() => {
                addServerIpInputRef.current.value = "";
                addServerDescInputRef.current.value = "";
                setAddServerModal(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ServersData;
