import React, { useState } from "react";
import { Button, Header } from "../../components";
import { useStatContext } from "../../contexts/ContextProvider";
import Modal from "../../components/Modal";
import { TiTicket } from "react-icons/ti";
import { getToken } from "../../utils/HttpUtils";
import { RiStarFill } from "react-icons/ri";
import { BiDevices, BiPause, BiPlay, BiUser } from "react-icons/bi";
import { BsClock } from "react-icons/bs";
import { SiLinux } from "react-icons/si";

const TokenGenerate = () => {
  const { setCurrentToast } = useStatContext();
  const [getTokenInfoModalOpen, setGetTokenInfoModalOpen] = useState(false);
  const [tokenObject, setTokenObject] = useState({});
  const [reqToken, setReqToken] = useState("");

  const resetGetTokenInfoValues = () => {
    setReqToken("");
  };

  let newTokenStr = "";
  if (
    tokenObject.token &&
    tokenObject.token.length > 1 &&
    tokenObject.token.length !== 19
  ) {
    for (var i = 0; i < tokenObject.token.length; i++) {
      if (i != 0 && i % 4 == 0) {
        newTokenStr += "-";
      }
      newTokenStr += tokenObject.token.charAt(i);
    }
  } else {
    newTokenStr = tokenObject.token;
  }

  return (
    <div
      className="relative m-10 p-5
    dark:bg-secondary-dark-bg font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Token" title="Token Info" />
      <div className="w-full">
        <button
          onClick={() => {
            setGetTokenInfoModalOpen(true);
          }}
          className="cybr-btn"
          style={{ "--primary-hue": 160 }}
        >
          Info
          <span aria-hidden className="cybr-btn__glitch">
            Info
          </span>
        </button>
      </div>
      <div className="flex justify-center mt-5">
        {Object.keys(tokenObject).length !== 0 && (
          <div className="relative flex flex-col w-1/2 py-8 border-1 border-[#1ffea9] p-5 mx-12 hover:bg-radial-gradient">
            <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
            <div className="absolute bg-[#1ffea9] right-0 top-0">
              <div className="flex items-center gap-1 p-1">
                <RiStarFill className="text-sm" />
                <span className="text-black text-sm font-semibold">
                  {tokenObject.id}
                </span>
              </div>
            </div>
            <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
            <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <TiTicket className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Token:</span>
              </div>

              <span className="text-black bg-white px-1 font-bold text-sm">
                {newTokenStr}
              </span>
            </div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <BiDevices className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Devices count:</span>
              </div>
              <div className="flex items-center">
                <p className="text-white text-sm">{tokenObject.device_num}</p>
                <p className="text-white ml-2 text-sm">device(s)</p>
              </div>
            </div>
            <div className="flex items-center gap-5 mt-4">
              <div className="flex items-center gap-2">
                <BsClock className="text-[#1ffea9] text-sm" />
                <span className="text-[#1ffea9] text-sm">Days:</span>
              </div>
              <div className="flex items-center">
                <p className="text-white text-sm">{tokenObject.days}</p>
                <p className="text-white ml-2 text-sm">day(s)</p>
              </div>
            </div>
            {tokenObject.user_id && tokenObject.user_id !== "" && (
              <div className="flex items-center gap-5 mt-4">
                <div className="flex items-center gap-2">
                  <BiUser className="text-[#1ffea9] text-sm" />
                  <span className="text-[#1ffea9] text-sm">User ID(s):</span>
                </div>
                <div className="flex items-center">
                  <p className="text-white text-[10px]">
                    {tokenObject.user_id}
                  </p>
                </div>
              </div>
            )}
            {tokenObject.start &&
              tokenObject.start !== "" &&
              tokenObject.start !== "0001-01-01T00:00:00Z" && (
                <div className="flex items-center gap-5 mt-4">
                  <div className="flex items-center gap-2">
                    <BiPlay className="text-[#1ffea9] text-sm" />
                    <span className="text-[#1ffea9] text-sm">Start time:</span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-white text-sm">
                      {tokenObject.start.slice(0, 19).replace("T", " ")}
                    </p>
                  </div>
                </div>
              )}
            {tokenObject.expire &&
              tokenObject.expire !== "" &&
              tokenObject.expire !== "0001-01-01T00:00:00Z" && (
                <div className="flex items-center gap-5 mt-4">
                  <div className="flex items-center gap-2">
                    <BiPause className="text-[#1ffea9] text-sm" />
                    <span className="text-[#1ffea9] text-sm">Expire time:</span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-white text-sm">
                      {tokenObject.expire.slice(0, 19).replace("T", " ")}
                    </p>
                  </div>
                </div>
              )}
            {tokenObject.os_id && tokenObject.os_id !== "" && (
              <div className="flex items-center gap-5 mt-4">
                <div className="flex items-center gap-2">
                  <SiLinux className="text-[#1ffea9] text-sm" />
                  <span className="text-[#1ffea9] text-sm">OS IDs:</span>
                </div>
                <div className="flex items-center">
                  <p className="text-white text-sm">{tokenObject.os_id}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        open={getTokenInfoModalOpen}
        onClose={() => {
          resetGetTokenInfoValues();
          setGetTokenInfoModalOpen(false);
        }}
      >
        <div className="text-center">
          <TiTicket className="mx-auto text-[#1ffea9] text-2xl" />
          <div className="my-4">
            <h3 className="text-lg font-black my-5 text-[#1ffea9]">
              Token Info
            </h3>
          </div>
          <div className={`flex flex-col items-start`}>
            <label className="text-white">Token:</label>
            <input
              value={reqToken}
              onChange={(e) => {
                const requestedToken = e.target.value;
                setReqToken(requestedToken);
              }}
              className="w-full px-1"
            />
          </div>

          <div className="flex gap-4 mt-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                try {
                  const result = await getToken(reqToken);
                  setTokenObject(result);
                  setCurrentToast({
                    isToasted: true,
                    text: "Success",
                    color: "[#1ffea9]",
                  });
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }
                //reset all values
                resetGetTokenInfoValues();
                setGetTokenInfoModalOpen(false);
              }}
            >
              Ok
            </button>
            <button
              className="bg-red-500 py-1 text-black w-full"
              onClick={() => {
                resetGetTokenInfoValues();
                setGetTokenInfoModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TokenGenerate;
